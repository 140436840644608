/* eslint-env browser */

<template>
  <link rel="stylesheet" href="https://unpkg.com/bpmn-js@17.11.1/dist/assets/bpmn-js.css">
  <link rel="stylesheet" href="https://unpkg.com/bpmn-js@17.11.1/dist/assets/diagram-js.css">
  <link rel="stylesheet" href="https://unpkg.com/bpmn-js@17.11.1/dist/assets/bpmn-font/css/bpmn.css">
  <router-view />
  <JoinExistingMeeting
    v-if="showJoinMeeting"
    :meetings="activeMeetings"
    @meeting-selected="handleMeetingSelected"
  />
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import JoinExistingMeeting from './components/JoinExistingMeeting.vue';
import { GameStreamingService } from './GameManager';
import './App.vue.css';
import { IGameStreamingService } from './types';
import { useRouter } from 'vue-router';
import { useAuthStore } from './stores/auth';
import type { Meeting, MeetingRoute } from './types';

// Interface defining the structure of component data
interface ComponentData {
  gameService: IGameStreamingService | null;
  showJoinMeeting: boolean;
  activeMeetings: Meeting[];
  onMeetingSelected: ((meetingId: string, route: MeetingRoute) => void) | null;
  showJoinMeetingHandler: ((event: CustomEvent) => void) | null;
  originalRoute: string | null;
}

export default defineComponent({
  components: {
    JoinExistingMeeting
  },
  setup() {
    const router = useRouter();
    const auth = useAuthStore();
    return { router, auth };
  },
  data(): ComponentData {
    return {
      gameService: null,
      showJoinMeeting: false,
      activeMeetings: [],
      onMeetingSelected: null,
      showJoinMeetingHandler: null,
      originalRoute: null
    };
  },
  async created() {
    // Add token expiration handler
    window.addEventListener('tokenExpired', this.handleTokenExpired);
    
    // Capture initial route if redirected to login
    const currentRoute = this.router.currentRoute.value;
    console.log('Current route:', currentRoute.path);
    console.log('Redirected from:', currentRoute.redirectedFrom?.fullPath);
    
    if (currentRoute.path === '/login' && currentRoute.redirectedFrom) {
        console.log('Capturing initial redirect from:', currentRoute.redirectedFrom.fullPath);
        this.auth.setOriginalRoute(currentRoute.redirectedFrom.fullPath);
    }

    this.gameService = GameStreamingService.initialize(
      this.$toast,  // toast: ToastPluginApi
      ""            // userToken: string - empty initially, set after Google auth
    );
  },
  mounted() {
    const handleShowJoinMeeting = ((event: CustomEvent) => {
      this.activeMeetings = event.detail.meetings;
      this.showJoinMeeting = true;
      this.onMeetingSelected = event.detail.onSelect;
    });

    window.addEventListener('showJoinMeeting', handleShowJoinMeeting as any);
    this.showJoinMeetingHandler = handleShowJoinMeeting;

    // Store the original route when the app first loads
    onMounted(() => {
      // Only save if we're not already on login and haven't already stored a route
      const currentPath = window.location.pathname + window.location.search + window.location.hash;
      console.log('Initial path on mount:', currentPath);
      console.log('Existing originalRoute in storage:', this.auth.originalRoute);
      
      if (currentPath !== '/' && 
          currentPath !== '/login' && 
          !this.auth.originalRoute) {
        console.log('Saving original route:', currentPath);
        this.auth.setOriginalRoute(currentPath);
      }
    });
  },
  methods: {
    handleTokenExpired() {
        console.log('Token expired, resetting auth state');
        const currentPath = window.location.pathname + window.location.search + window.location.hash;
        console.log('Current path:', currentPath);
        if (currentPath !== '/login') {
            console.log('Storing route before token expiration redirect:', currentPath);
            this.auth.setOriginalRoute(currentPath);
        }
        
        // Clean up existing connections
        if (this.gameService) {
            GameStreamingService.reset();
        }
        // Reset auth state using store
        this.auth.clearAuth();
        // Navigate to login using router
        this.router.push('/login');
    },
    handleMeetingSelected(meetingId: string, route: MeetingRoute) {
      if (this.onMeetingSelected) {
        this.onMeetingSelected(meetingId, route);
        this.showJoinMeeting = false;
      }
    },
    isValidRoute(route: string): boolean {
      // Get the path without query parameters
      const path = route.split('?')[0];
      
      // Check if this path exists in our router's routes
      return this.router.hasRoute(path.slice(1)); // slice(1) removes leading slash
    },
    async checkAndRestoreRoute() {
        const originalRoute = this.auth.originalRoute;
        console.log('Checking for route restoration. originalRoute:', originalRoute);
        
        if (originalRoute && this.isValidRoute(originalRoute)) {
            console.log('Restoring route to:', originalRoute);
            await this.router.push(originalRoute);
            // Clear the stored route after restoration
            this.auth.setOriginalRoute(null);
        } else {
            console.log('No original route to restore');
        }
    }
  },
  watch: {
    'auth.isAuthenticated'(newValue: boolean) {
      if (newValue) {
        this.checkAndRestoreRoute();
      }
    }
  },
  beforeUnmount() {
    // Remove event listeners
    window.removeEventListener('tokenExpired', this.handleTokenExpired);
    if (this.showJoinMeetingHandler) {
      window.removeEventListener('showJoinMeeting', this.showJoinMeetingHandler as any);
    }
  }
});
</script>
