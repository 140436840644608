// source: audio.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.audio.AudioFileUpload', null, global);
goog.exportSymbol('proto.audio.AudioTranscript', null, global);
goog.exportSymbol('proto.audio.ClientConnectRequest', null, global);
goog.exportSymbol('proto.audio.ParticipantList', null, global);
goog.exportSymbol('proto.audio.ToAudioClient', null, global);
goog.exportSymbol('proto.audio.ToAudioClient.TypeCase', null, global);
goog.exportSymbol('proto.audio.ToAudioServer', null, global);
goog.exportSymbol('proto.audio.ToAudioServer.ContentCase', null, global);
goog.exportSymbol('proto.audio.UpstreamState', null, global);
goog.exportSymbol('proto.audio.UpstreamStatuses', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.ToAudioServer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.audio.ToAudioServer.oneofGroups_);
};
goog.inherits(proto.audio.ToAudioServer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.ToAudioServer.displayName = 'proto.audio.ToAudioServer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.UpstreamStatuses = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audio.UpstreamStatuses, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.UpstreamStatuses.displayName = 'proto.audio.UpstreamStatuses';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.ParticipantList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.audio.ParticipantList.repeatedFields_, null);
};
goog.inherits(proto.audio.ParticipantList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.ParticipantList.displayName = 'proto.audio.ParticipantList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.ToAudioClient = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.audio.ToAudioClient.oneofGroups_);
};
goog.inherits(proto.audio.ToAudioClient, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.ToAudioClient.displayName = 'proto.audio.ToAudioClient';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.ClientConnectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audio.ClientConnectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.ClientConnectRequest.displayName = 'proto.audio.ClientConnectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.AudioFileUpload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audio.AudioFileUpload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.AudioFileUpload.displayName = 'proto.audio.AudioFileUpload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.AudioTranscript = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audio.AudioTranscript, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.AudioTranscript.displayName = 'proto.audio.AudioTranscript';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.audio.ToAudioServer.oneofGroups_ = [[1,2,3,4,5,6,7]];

/**
 * @enum {number}
 */
proto.audio.ToAudioServer.ContentCase = {
  CONTENT_NOT_SET: 0,
  CONNREQ: 1,
  AUDIO_REQUEST: 2,
  USER_SUBMITTED_TEXT: 3,
  TOGGLE_MONOLOG_MODE: 4,
  TOGGLE_AI_OBSERVER_MODE: 5,
  TERMINATE_MEETING: 6,
  PING: 7
};

/**
 * @return {proto.audio.ToAudioServer.ContentCase}
 */
proto.audio.ToAudioServer.prototype.getContentCase = function() {
  return /** @type {proto.audio.ToAudioServer.ContentCase} */(jspb.Message.computeOneofCase(this, proto.audio.ToAudioServer.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.ToAudioServer.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.ToAudioServer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.ToAudioServer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ToAudioServer.toObject = function(includeInstance, msg) {
  var f, obj = {
    connreq: (f = msg.getConnreq()) && proto.audio.ClientConnectRequest.toObject(includeInstance, f),
    audioRequest: (f = msg.getAudioRequest()) && proto.audio.AudioFileUpload.toObject(includeInstance, f),
    userSubmittedText: jspb.Message.getFieldWithDefault(msg, 3, ""),
    toggleMonologMode: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    toggleAiObserverMode: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    terminateMeeting: jspb.Message.getFieldWithDefault(msg, 6, ""),
    ping: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.ToAudioServer}
 */
proto.audio.ToAudioServer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.ToAudioServer;
  return proto.audio.ToAudioServer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.ToAudioServer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.ToAudioServer}
 */
proto.audio.ToAudioServer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.audio.ClientConnectRequest;
      reader.readMessage(value,proto.audio.ClientConnectRequest.deserializeBinaryFromReader);
      msg.setConnreq(value);
      break;
    case 2:
      var value = new proto.audio.AudioFileUpload;
      reader.readMessage(value,proto.audio.AudioFileUpload.deserializeBinaryFromReader);
      msg.setAudioRequest(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserSubmittedText(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setToggleMonologMode(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setToggleAiObserverMode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerminateMeeting(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.ToAudioServer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.ToAudioServer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.ToAudioServer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ToAudioServer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConnreq();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.audio.ClientConnectRequest.serializeBinaryToWriter
    );
  }
  f = message.getAudioRequest();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.audio.AudioFileUpload.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional ClientConnectRequest connreq = 1;
 * @return {?proto.audio.ClientConnectRequest}
 */
proto.audio.ToAudioServer.prototype.getConnreq = function() {
  return /** @type{?proto.audio.ClientConnectRequest} */ (
    jspb.Message.getWrapperField(this, proto.audio.ClientConnectRequest, 1));
};


/**
 * @param {?proto.audio.ClientConnectRequest|undefined} value
 * @return {!proto.audio.ToAudioServer} returns this
*/
proto.audio.ToAudioServer.prototype.setConnreq = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.audio.ToAudioServer.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ToAudioServer} returns this
 */
proto.audio.ToAudioServer.prototype.clearConnreq = function() {
  return this.setConnreq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioServer.prototype.hasConnreq = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AudioFileUpload audio_request = 2;
 * @return {?proto.audio.AudioFileUpload}
 */
proto.audio.ToAudioServer.prototype.getAudioRequest = function() {
  return /** @type{?proto.audio.AudioFileUpload} */ (
    jspb.Message.getWrapperField(this, proto.audio.AudioFileUpload, 2));
};


/**
 * @param {?proto.audio.AudioFileUpload|undefined} value
 * @return {!proto.audio.ToAudioServer} returns this
*/
proto.audio.ToAudioServer.prototype.setAudioRequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.audio.ToAudioServer.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ToAudioServer} returns this
 */
proto.audio.ToAudioServer.prototype.clearAudioRequest = function() {
  return this.setAudioRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioServer.prototype.hasAudioRequest = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string user_submitted_text = 3;
 * @return {string}
 */
proto.audio.ToAudioServer.prototype.getUserSubmittedText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.ToAudioServer} returns this
 */
proto.audio.ToAudioServer.prototype.setUserSubmittedText = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.audio.ToAudioServer.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.audio.ToAudioServer} returns this
 */
proto.audio.ToAudioServer.prototype.clearUserSubmittedText = function() {
  return jspb.Message.setOneofField(this, 3, proto.audio.ToAudioServer.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioServer.prototype.hasUserSubmittedText = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool toggle_monolog_mode = 4;
 * @return {boolean}
 */
proto.audio.ToAudioServer.prototype.getToggleMonologMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audio.ToAudioServer} returns this
 */
proto.audio.ToAudioServer.prototype.setToggleMonologMode = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.audio.ToAudioServer.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.audio.ToAudioServer} returns this
 */
proto.audio.ToAudioServer.prototype.clearToggleMonologMode = function() {
  return jspb.Message.setOneofField(this, 4, proto.audio.ToAudioServer.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioServer.prototype.hasToggleMonologMode = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool toggle_ai_observer_mode = 5;
 * @return {boolean}
 */
proto.audio.ToAudioServer.prototype.getToggleAiObserverMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audio.ToAudioServer} returns this
 */
proto.audio.ToAudioServer.prototype.setToggleAiObserverMode = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.audio.ToAudioServer.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.audio.ToAudioServer} returns this
 */
proto.audio.ToAudioServer.prototype.clearToggleAiObserverMode = function() {
  return jspb.Message.setOneofField(this, 5, proto.audio.ToAudioServer.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioServer.prototype.hasToggleAiObserverMode = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string terminate_meeting = 6;
 * @return {string}
 */
proto.audio.ToAudioServer.prototype.getTerminateMeeting = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.ToAudioServer} returns this
 */
proto.audio.ToAudioServer.prototype.setTerminateMeeting = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.audio.ToAudioServer.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.audio.ToAudioServer} returns this
 */
proto.audio.ToAudioServer.prototype.clearTerminateMeeting = function() {
  return jspb.Message.setOneofField(this, 6, proto.audio.ToAudioServer.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioServer.prototype.hasTerminateMeeting = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 ping = 7;
 * @return {number}
 */
proto.audio.ToAudioServer.prototype.getPing = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.audio.ToAudioServer} returns this
 */
proto.audio.ToAudioServer.prototype.setPing = function(value) {
  return jspb.Message.setOneofField(this, 7, proto.audio.ToAudioServer.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.audio.ToAudioServer} returns this
 */
proto.audio.ToAudioServer.prototype.clearPing = function() {
  return jspb.Message.setOneofField(this, 7, proto.audio.ToAudioServer.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioServer.prototype.hasPing = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.UpstreamStatuses.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.UpstreamStatuses.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.UpstreamStatuses} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.UpstreamStatuses.toObject = function(includeInstance, msg) {
  var f, obj = {
    primary: jspb.Message.getFieldWithDefault(msg, 1, 0),
    observer: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.UpstreamStatuses}
 */
proto.audio.UpstreamStatuses.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.UpstreamStatuses;
  return proto.audio.UpstreamStatuses.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.UpstreamStatuses} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.UpstreamStatuses}
 */
proto.audio.UpstreamStatuses.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.audio.UpstreamState} */ (reader.readEnum());
      msg.setPrimary(value);
      break;
    case 2:
      var value = /** @type {!proto.audio.UpstreamState} */ (reader.readEnum());
      msg.setObserver(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.UpstreamStatuses.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.UpstreamStatuses.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.UpstreamStatuses} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.UpstreamStatuses.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrimary();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getObserver();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional UpstreamState primary = 1;
 * @return {!proto.audio.UpstreamState}
 */
proto.audio.UpstreamStatuses.prototype.getPrimary = function() {
  return /** @type {!proto.audio.UpstreamState} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.audio.UpstreamState} value
 * @return {!proto.audio.UpstreamStatuses} returns this
 */
proto.audio.UpstreamStatuses.prototype.setPrimary = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional UpstreamState observer = 2;
 * @return {!proto.audio.UpstreamState}
 */
proto.audio.UpstreamStatuses.prototype.getObserver = function() {
  return /** @type {!proto.audio.UpstreamState} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.audio.UpstreamState} value
 * @return {!proto.audio.UpstreamStatuses} returns this
 */
proto.audio.UpstreamStatuses.prototype.setObserver = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.audio.ParticipantList.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.ParticipantList.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.ParticipantList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.ParticipantList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ParticipantList.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerUuidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    participantsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    myUuid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.ParticipantList}
 */
proto.audio.ParticipantList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.ParticipantList;
  return proto.audio.ParticipantList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.ParticipantList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.ParticipantList}
 */
proto.audio.ParticipantList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addOwnerUuids(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addParticipants(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMyUuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.ParticipantList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.ParticipantList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.ParticipantList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ParticipantList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerUuidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getParticipantsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getMyUuid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated string owner_uuids = 1;
 * @return {!Array<string>}
 */
proto.audio.ParticipantList.prototype.getOwnerUuidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.audio.ParticipantList} returns this
 */
proto.audio.ParticipantList.prototype.setOwnerUuidsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.audio.ParticipantList} returns this
 */
proto.audio.ParticipantList.prototype.addOwnerUuids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audio.ParticipantList} returns this
 */
proto.audio.ParticipantList.prototype.clearOwnerUuidsList = function() {
  return this.setOwnerUuidsList([]);
};


/**
 * repeated string participants = 2;
 * @return {!Array<string>}
 */
proto.audio.ParticipantList.prototype.getParticipantsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.audio.ParticipantList} returns this
 */
proto.audio.ParticipantList.prototype.setParticipantsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.audio.ParticipantList} returns this
 */
proto.audio.ParticipantList.prototype.addParticipants = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audio.ParticipantList} returns this
 */
proto.audio.ParticipantList.prototype.clearParticipantsList = function() {
  return this.setParticipantsList([]);
};


/**
 * optional string my_uuid = 3;
 * @return {string}
 */
proto.audio.ParticipantList.prototype.getMyUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.ParticipantList} returns this
 */
proto.audio.ParticipantList.prototype.setMyUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.audio.ToAudioClient.oneofGroups_ = [[1,2,3,4,5,6]];

/**
 * @enum {number}
 */
proto.audio.ToAudioClient.TypeCase = {
  TYPE_NOT_SET: 0,
  AUDIO_RESPONSE: 1,
  AUDIO_TRANSCRIPT: 2,
  UPSTREAM_STATUSES: 3,
  MEETING_TERMINATED: 4,
  PARTICIPANT_LIST: 5,
  INPUT_AUDIO_START_STOP: 6
};

/**
 * @return {proto.audio.ToAudioClient.TypeCase}
 */
proto.audio.ToAudioClient.prototype.getTypeCase = function() {
  return /** @type {proto.audio.ToAudioClient.TypeCase} */(jspb.Message.computeOneofCase(this, proto.audio.ToAudioClient.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.ToAudioClient.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.ToAudioClient.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.ToAudioClient} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ToAudioClient.toObject = function(includeInstance, msg) {
  var f, obj = {
    audioResponse: (f = msg.getAudioResponse()) && proto.audio.AudioFileUpload.toObject(includeInstance, f),
    audioTranscript: (f = msg.getAudioTranscript()) && proto.audio.AudioTranscript.toObject(includeInstance, f),
    upstreamStatuses: (f = msg.getUpstreamStatuses()) && proto.audio.UpstreamStatuses.toObject(includeInstance, f),
    meetingTerminated: jspb.Message.getFieldWithDefault(msg, 4, ""),
    participantList: (f = msg.getParticipantList()) && proto.audio.ParticipantList.toObject(includeInstance, f),
    inputAudioStartStop: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.ToAudioClient}
 */
proto.audio.ToAudioClient.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.ToAudioClient;
  return proto.audio.ToAudioClient.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.ToAudioClient} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.ToAudioClient}
 */
proto.audio.ToAudioClient.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.audio.AudioFileUpload;
      reader.readMessage(value,proto.audio.AudioFileUpload.deserializeBinaryFromReader);
      msg.setAudioResponse(value);
      break;
    case 2:
      var value = new proto.audio.AudioTranscript;
      reader.readMessage(value,proto.audio.AudioTranscript.deserializeBinaryFromReader);
      msg.setAudioTranscript(value);
      break;
    case 3:
      var value = new proto.audio.UpstreamStatuses;
      reader.readMessage(value,proto.audio.UpstreamStatuses.deserializeBinaryFromReader);
      msg.setUpstreamStatuses(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeetingTerminated(value);
      break;
    case 5:
      var value = new proto.audio.ParticipantList;
      reader.readMessage(value,proto.audio.ParticipantList.deserializeBinaryFromReader);
      msg.setParticipantList(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInputAudioStartStop(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.ToAudioClient.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.ToAudioClient.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.ToAudioClient} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ToAudioClient.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAudioResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.audio.AudioFileUpload.serializeBinaryToWriter
    );
  }
  f = message.getAudioTranscript();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.audio.AudioTranscript.serializeBinaryToWriter
    );
  }
  f = message.getUpstreamStatuses();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.audio.UpstreamStatuses.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getParticipantList();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.audio.ParticipantList.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional AudioFileUpload audio_response = 1;
 * @return {?proto.audio.AudioFileUpload}
 */
proto.audio.ToAudioClient.prototype.getAudioResponse = function() {
  return /** @type{?proto.audio.AudioFileUpload} */ (
    jspb.Message.getWrapperField(this, proto.audio.AudioFileUpload, 1));
};


/**
 * @param {?proto.audio.AudioFileUpload|undefined} value
 * @return {!proto.audio.ToAudioClient} returns this
*/
proto.audio.ToAudioClient.prototype.setAudioResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.audio.ToAudioClient.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ToAudioClient} returns this
 */
proto.audio.ToAudioClient.prototype.clearAudioResponse = function() {
  return this.setAudioResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioClient.prototype.hasAudioResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AudioTranscript audio_transcript = 2;
 * @return {?proto.audio.AudioTranscript}
 */
proto.audio.ToAudioClient.prototype.getAudioTranscript = function() {
  return /** @type{?proto.audio.AudioTranscript} */ (
    jspb.Message.getWrapperField(this, proto.audio.AudioTranscript, 2));
};


/**
 * @param {?proto.audio.AudioTranscript|undefined} value
 * @return {!proto.audio.ToAudioClient} returns this
*/
proto.audio.ToAudioClient.prototype.setAudioTranscript = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.audio.ToAudioClient.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ToAudioClient} returns this
 */
proto.audio.ToAudioClient.prototype.clearAudioTranscript = function() {
  return this.setAudioTranscript(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioClient.prototype.hasAudioTranscript = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional UpstreamStatuses upstream_statuses = 3;
 * @return {?proto.audio.UpstreamStatuses}
 */
proto.audio.ToAudioClient.prototype.getUpstreamStatuses = function() {
  return /** @type{?proto.audio.UpstreamStatuses} */ (
    jspb.Message.getWrapperField(this, proto.audio.UpstreamStatuses, 3));
};


/**
 * @param {?proto.audio.UpstreamStatuses|undefined} value
 * @return {!proto.audio.ToAudioClient} returns this
*/
proto.audio.ToAudioClient.prototype.setUpstreamStatuses = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.audio.ToAudioClient.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ToAudioClient} returns this
 */
proto.audio.ToAudioClient.prototype.clearUpstreamStatuses = function() {
  return this.setUpstreamStatuses(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioClient.prototype.hasUpstreamStatuses = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string meeting_terminated = 4;
 * @return {string}
 */
proto.audio.ToAudioClient.prototype.getMeetingTerminated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.ToAudioClient} returns this
 */
proto.audio.ToAudioClient.prototype.setMeetingTerminated = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.audio.ToAudioClient.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.audio.ToAudioClient} returns this
 */
proto.audio.ToAudioClient.prototype.clearMeetingTerminated = function() {
  return jspb.Message.setOneofField(this, 4, proto.audio.ToAudioClient.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioClient.prototype.hasMeetingTerminated = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ParticipantList participant_list = 5;
 * @return {?proto.audio.ParticipantList}
 */
proto.audio.ToAudioClient.prototype.getParticipantList = function() {
  return /** @type{?proto.audio.ParticipantList} */ (
    jspb.Message.getWrapperField(this, proto.audio.ParticipantList, 5));
};


/**
 * @param {?proto.audio.ParticipantList|undefined} value
 * @return {!proto.audio.ToAudioClient} returns this
*/
proto.audio.ToAudioClient.prototype.setParticipantList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.audio.ToAudioClient.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ToAudioClient} returns this
 */
proto.audio.ToAudioClient.prototype.clearParticipantList = function() {
  return this.setParticipantList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioClient.prototype.hasParticipantList = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool input_audio_start_stop = 6;
 * @return {boolean}
 */
proto.audio.ToAudioClient.prototype.getInputAudioStartStop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audio.ToAudioClient} returns this
 */
proto.audio.ToAudioClient.prototype.setInputAudioStartStop = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.audio.ToAudioClient.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.audio.ToAudioClient} returns this
 */
proto.audio.ToAudioClient.prototype.clearInputAudioStartStop = function() {
  return jspb.Message.setOneofField(this, 6, proto.audio.ToAudioClient.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioClient.prototype.hasInputAudioStartStop = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.ClientConnectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.ClientConnectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.ClientConnectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ClientConnectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    authKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userIdent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    openAiApiKey: jspb.Message.getFieldWithDefault(msg, 3, ""),
    openAiChatModel: jspb.Message.getFieldWithDefault(msg, 4, ""),
    userAudioMeetingId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.ClientConnectRequest}
 */
proto.audio.ClientConnectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.ClientConnectRequest;
  return proto.audio.ClientConnectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.ClientConnectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.ClientConnectRequest}
 */
proto.audio.ClientConnectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserIdent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpenAiApiKey(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpenAiChatModel(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserAudioMeetingId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.ClientConnectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.ClientConnectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.ClientConnectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ClientConnectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserIdent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOpenAiApiKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOpenAiChatModel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUserAudioMeetingId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string auth_key = 1;
 * @return {string}
 */
proto.audio.ClientConnectRequest.prototype.getAuthKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.ClientConnectRequest} returns this
 */
proto.audio.ClientConnectRequest.prototype.setAuthKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_ident = 2;
 * @return {string}
 */
proto.audio.ClientConnectRequest.prototype.getUserIdent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.ClientConnectRequest} returns this
 */
proto.audio.ClientConnectRequest.prototype.setUserIdent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string open_ai_api_key = 3;
 * @return {string}
 */
proto.audio.ClientConnectRequest.prototype.getOpenAiApiKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.ClientConnectRequest} returns this
 */
proto.audio.ClientConnectRequest.prototype.setOpenAiApiKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string open_ai_chat_model = 4;
 * @return {string}
 */
proto.audio.ClientConnectRequest.prototype.getOpenAiChatModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.ClientConnectRequest} returns this
 */
proto.audio.ClientConnectRequest.prototype.setOpenAiChatModel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string user_audio_meeting_id = 5;
 * @return {string}
 */
proto.audio.ClientConnectRequest.prototype.getUserAudioMeetingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.ClientConnectRequest} returns this
 */
proto.audio.ClientConnectRequest.prototype.setUserAudioMeetingId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.AudioFileUpload.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.AudioFileUpload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.AudioFileUpload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.AudioFileUpload.toObject = function(includeInstance, msg) {
  var f, obj = {
    audio: msg.getAudio_asB64(),
    audioType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fileName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sequenceNumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    phaseNumber: jspb.Message.getFieldWithDefault(msg, 6, 0),
    audioDurationMs: jspb.Message.getFieldWithDefault(msg, 10, 0),
    userIdent: jspb.Message.getFieldWithDefault(msg, 7, ""),
    openAiApiKey: jspb.Message.getFieldWithDefault(msg, 8, ""),
    openAiChatModel: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.AudioFileUpload}
 */
proto.audio.AudioFileUpload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.AudioFileUpload;
  return proto.audio.AudioFileUpload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.AudioFileUpload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.AudioFileUpload}
 */
proto.audio.AudioFileUpload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAudio(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAudioType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSequenceNumber(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPhaseNumber(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAudioDurationMs(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserIdent(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpenAiApiKey(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpenAiChatModel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.AudioFileUpload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.AudioFileUpload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.AudioFileUpload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.AudioFileUpload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAudio_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getAudioType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSequenceNumber();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getPhaseNumber();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getAudioDurationMs();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getUserIdent();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOpenAiApiKey();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOpenAiChatModel();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional bytes audio = 1;
 * @return {!(string|Uint8Array)}
 */
proto.audio.AudioFileUpload.prototype.getAudio = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes audio = 1;
 * This is a type-conversion wrapper around `getAudio()`
 * @return {string}
 */
proto.audio.AudioFileUpload.prototype.getAudio_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAudio()));
};


/**
 * optional bytes audio = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAudio()`
 * @return {!Uint8Array}
 */
proto.audio.AudioFileUpload.prototype.getAudio_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAudio()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.audio.AudioFileUpload} returns this
 */
proto.audio.AudioFileUpload.prototype.setAudio = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string audio_type = 2;
 * @return {string}
 */
proto.audio.AudioFileUpload.prototype.getAudioType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioFileUpload} returns this
 */
proto.audio.AudioFileUpload.prototype.setAudioType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string item_id = 3;
 * @return {string}
 */
proto.audio.AudioFileUpload.prototype.getItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioFileUpload} returns this
 */
proto.audio.AudioFileUpload.prototype.setItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string file_name = 4;
 * @return {string}
 */
proto.audio.AudioFileUpload.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioFileUpload} returns this
 */
proto.audio.AudioFileUpload.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 sequence_number = 5;
 * @return {number}
 */
proto.audio.AudioFileUpload.prototype.getSequenceNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.audio.AudioFileUpload} returns this
 */
proto.audio.AudioFileUpload.prototype.setSequenceNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 phase_number = 6;
 * @return {number}
 */
proto.audio.AudioFileUpload.prototype.getPhaseNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.audio.AudioFileUpload} returns this
 */
proto.audio.AudioFileUpload.prototype.setPhaseNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 audio_duration_ms = 10;
 * @return {number}
 */
proto.audio.AudioFileUpload.prototype.getAudioDurationMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.audio.AudioFileUpload} returns this
 */
proto.audio.AudioFileUpload.prototype.setAudioDurationMs = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string user_ident = 7;
 * @return {string}
 */
proto.audio.AudioFileUpload.prototype.getUserIdent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioFileUpload} returns this
 */
proto.audio.AudioFileUpload.prototype.setUserIdent = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string open_ai_api_key = 8;
 * @return {string}
 */
proto.audio.AudioFileUpload.prototype.getOpenAiApiKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioFileUpload} returns this
 */
proto.audio.AudioFileUpload.prototype.setOpenAiApiKey = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string open_ai_chat_model = 9;
 * @return {string}
 */
proto.audio.AudioFileUpload.prototype.getOpenAiChatModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioFileUpload} returns this
 */
proto.audio.AudioFileUpload.prototype.setOpenAiChatModel = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.AudioTranscript.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.AudioTranscript.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.AudioTranscript} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.AudioTranscript.toObject = function(includeInstance, msg) {
  var f, obj = {
    source: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.AudioTranscript}
 */
proto.audio.AudioTranscript.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.AudioTranscript;
  return proto.audio.AudioTranscript.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.AudioTranscript} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.AudioTranscript}
 */
proto.audio.AudioTranscript.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.AudioTranscript.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.AudioTranscript.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.AudioTranscript} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.AudioTranscript.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string source = 1;
 * @return {string}
 */
proto.audio.AudioTranscript.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioTranscript} returns this
 */
proto.audio.AudioTranscript.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.audio.AudioTranscript.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioTranscript} returns this
 */
proto.audio.AudioTranscript.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string item_id = 3;
 * @return {string}
 */
proto.audio.AudioTranscript.prototype.getItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioTranscript} returns this
 */
proto.audio.AudioTranscript.prototype.setItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.audio.UpstreamState = {
  NO_CONNECTION: 0,
  CONN_BIDIR: 1,
  CONN_MONOLOG: 2
};

goog.object.extend(exports, proto.audio);
