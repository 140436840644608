<template>
  <div id="dev-chat" :class="{ 'sidebar-open': isMenuOpen }">

    <!-- Sidebar menu -->
    <div class="sidebar" :class="{ 'open': isMenuOpen }" @click.stop>
      <div class="sidebar-header">
        <img src="@/assets/automab-antibody-at.svg" alt="AutoMAb Logo" class="sidebar-logo" />
      </div>
      <div class="sidebar-top">
        <button @click="openSettings" title="Settings">
          <img src="./assets/settings.svg" alt="settings" class="icon" />
        </button>
        <button @click="openFilesModal" title="Manage Files">
          <img src="./assets/file.svg" alt="folder" class="icon" />
        </button>
        <button @click="reset" title="Reset Chat">
          <img src="./assets/reset.svg" alt="reset" class="icon" />
        </button>
        <button @click="toggleFilter" :title="showFilter ? 'Hide Filter' : 'Show Filter'">
          <img :src="showFilter ? noFilterIcon : filterIcon" :alt="showFilter ? 'filter' : 'no filter'" class="icon" />
        </button>
        <button @click="openBpmnModeler" title="Open BPMN Modeler">
          <img src="./assets/bpmn.svg" alt="bpmn" class="icon" />
        </button>
        <button @click="openConfigEditor" title="Edit Config">
          <img src="./assets/config.svg" alt="code" class="icon" />
        </button>
        <button @click="openViewer" v-if="auth.automab" title="3D Viewer">
          <img src="./assets/3D-cube.svg" alt="viewer" class="icon" />
        </button>
        <button @click="openGame" v-if="!auth.automab" title="Open Game">
          <img src="./assets/tag.svg" alt="game" class="icon" />
        </button>
        <button @click="cycleIconState" :title="getIconTitle">
          <img :src="getCurrentIcon" 
               :alt="getIconTitle" 
               class="icon" />
        </button>
        <button @click="cycleObserverIconState" :title="getObserverIconTitle" class="observer-button">
          <img :src="getObserverIcon" 
               :alt="getObserverIconTitle" 
               class="icon" />
        </button>
      </div>
      <div class="sidebar-bottom">
        <button @click="toggleAudioMuting" class="audio-control" :title="isMicMuted ? 'Unmute mic' : 'Mute mic'">
          <img :src="isMicMuted ? micOffIcon : micOnIcon" :alt="isMicMuted ? 'mic muted' : 'mic'" class="icon" />
        </button>
      </div>
    </div>

    <div class="filter-container" ref="filterContainer">
      <MessageFilter v-if="showFilter" @apply-simple-filter="applySimpleFilter" @apply-mongo-filter="applyMongoFilter"
        @generate-filter="handleGenerateFilter" />
    </div>

    <div v-if="isAutomabVisible" class="automab-tabs-container">
      <div class="tabs-header">
        <div v-for="(tab, index) in automabTabs" :key="index" class="tab-button"
          :class="{ active: activeTabIndex === index }" @click="switchTab(index)">
          <input v-if="editingTabIndex === index" v-model="tab.name" @blur="finishEditing" @keyup.enter="finishEditing"
            :ref="el => setTabInputRef(el, index)" class="tab-name-input" @click.stop />
          <span v-else @dblclick.stop="startEditing(index)">{{ tab.name }}</span>
          <button class="close-tab" @click.stop="closeTab(index)">&times;</button>
        </div>
        <button class="add-tab" @click="addNewTab">+</button>
      </div>
      <div class="tabs-content">
        <div v-for="(tab, index) in automabTabs" :key="index" class="automab-html"
          :class="{ active: activeTabIndex === index }" :ref="el => setTabRef(el, index)">
        </div>
      </div>
    </div>

    <!-- Chat content stack -->
    <ChatContent v-for="(chatMessages, index) in filteredChatStack" :key="index" :messages="chatMessages"
      :isLoading="isLoading && index === filteredChatStack.length - 1"
      :showTriggerSystem2Button="showTriggerSystem2Button && index === 0" :depth="index"
      @triggerSystem2="triggerSystem2" @openTraceInfo="openTraceInfo" @close="closeTraceInfo(index)"
      @sendMessage="sendMessage" @attach-file="handleAttachment" class="chat-content" />

    <!-- Modals -->
    <TagEditorModal v-if="isTagEditorOpen" :processorService='chatService' @close="closeTagEditor" />
    <CorporateAIEditorModal v-if="isCorporateAIEditorOpen" :processorService="chatService"
      @close="closeCorporateAIEditor" />
    <SettingsModal v-if="isSettingsOpen" @close="closeSettings" @save="saveSettings" @pair="handlePair" />
    <ViewerModal v-if="isViewerOpen && auth.automab" :searchString="viewerSearchString" @close="closeViewer" />
    <FilesModal v-if="isFilesModalOpen" :processorService="chatService" @close="closeFilesModal" />
    
    <!-- Side-by-side container for BPMN and Config editors -->
    <div v-if="isBpmnModelerOpen || isConfigEditorOpen" 
         class="side-by-side-container"
         :class="{ 'sidebar-open': isMenuOpen }">
      <!-- Inline BPMN modeler -->
      <div v-if="isBpmnModelerOpen" class="side-panel">
        <BpmnModelerModal
          :processorService="chatService"
          @close="closeBpmnModeler"
          @node-selected="handleBpmnNodeSelected"
          @node-deleted="handleBpmnNodeDeleted"
          :inlineMode="true"
          @save="handleBpmnSave"
          :hasUnsavedChanges="hasUnsavedChanges"
          @model-changed="onModelChanged"
          @factory-reset="handleFactoryReset"
        />
      </div>

      <!-- Inline Config editor -->
      <div v-if="isConfigEditorOpen" class="side-panel">
        <ConfigEditorModal
          ref="configEditorModal"
          :processorService="chatService"
          :show="isConfigEditorOpen"
          :selected-node="selectedBpmnNode"
          @close="closeConfigEditor"
          :inlineMode="true"
          @config-changed="onConfigChanged"
        />
      </div>
    </div>
  </div>

  <!-- Status bar -->
  <div class="dev-chat-status-bar">
    <div class="status-section">
      <div class="status-item">
        <span class="label">User:</span>
        <span class="value">{{ auth.userName }}</span>
      </div>
      <div class="status-item">
        <span class="label">Email:</span>
        <span class="value">{{ auth.userEmail }}</span>
      </div>
      <button class="status-button" @click="handleShare" title="Share">
        <span class="status-icon">⇪</span>
        <span class="status-text">Share</span>
      </button>
      <button class="status-button" @click="handleReset" title="Reset">
        <span class="status-icon">↺</span>
        <span class="status-text">Reset</span>
      </button>
      <button class="status-button" @click="handleLogout" title="Logout">
        <span class="status-icon">⏻</span>
        <span class="status-text">Logout</span>
      </button>
      <div v-if="taskContext" class="status-item task-context">
        <span class="label">Task:</span>
        <span class="value">{{ taskContext }}</span>
      </div>
    </div>
    <div class="status-item server-status">
      <span class="label">Server:</span>
      <span class="value">{{ auth.server }}</span>
      <select v-if="isAdmin" class="server-select" :value="auth.server"
        @change="(e: Event) => switchServer((e.target as HTMLSelectElement).value)">
        <option value="" disabled selected>Switch</option>
        <option v-for="s in availableServers" :key="s" :value="s" :selected="s === auth.server">
          {{ s }}
        </option>
      </select>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, ComponentPublicInstance, nextTick, onUnmounted } from 'vue';
import { ProcessorService } from './ProcessorService';
import { Message, Settings } from './types';
import TagEditorModal from './TagEditorModal.vue';
import CorporateAIEditorModal from './CorporateAIEditorModal.vue';
import SettingsModal from './SettingsModal.vue';
import ViewerModal from './ViewerModal.vue';
import FilesModal from './FilesModal.vue';
import BpmnModelerModal from './BpmnModelerModal.vue';
import { useToast } from 'vue-toast-notification';
import ChatContent from './ChatContent.vue';
import MessageFilter from './MessageFilter.vue';
import './DevChat.vue.css';
import { filterMessages } from "./jsonQuery"
import { RealtimeAudioStreamingService } from './RealtimeApiAudioManager';
import { GameStreamingService } from './GameManager';
import { ToGameClient } from '../proto-gen-ref/game_pb';
import { UpstreamState } from '../proto-gen-ref/audio_pb';
import ConfigEditorModal from './ConfigEditorModal.vue';
import { AutomabService } from './services/AutomabService';
import micOnIcon from './assets/mic-on-at.svg'
import micOffIcon from './assets/mic-off-at.svg'
import filterIcon from './assets/filter.svg'
import noFilterIcon from './assets/no-filter.svg'
import type { BpmnNode } from './types/bpmn';
import { Buffer } from 'buffer';
import { useRouter } from 'vue-router';
import { useAuthStore } from './stores/auth';

import upstm_disconnected from './assets/upstm_disconnected-signal_disconnected_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg'
import upstm_conn_bidir from './assets/upstm_conn_bidir-sync_alt_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg'
import upstm_conn_monolog from './assets/upstm_conn_monolog-guardian_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg'

interface AutomabTab {
  name: string;
  shadowRoot: ShadowRoot | null;
}

export default defineComponent({
  name: 'DevChat',
  components: {
    TagEditorModal,
    CorporateAIEditorModal,
    SettingsModal,
    ViewerModal,
    FilesModal,
    BpmnModelerModal,
    ChatContent,
    MessageFilter,
    ConfigEditorModal,
  },
  setup() {
    const $toast = useToast();
    const chatStack = ref<Message[][]>([[]]);
    const activeFilter = ref<string>('');
    const activeFilterType = ref<string>('simple');
    const showFilter = ref(false);
    const filterContainer = ref<HTMLElement | null>(null);
    const isMenuOpen = ref(true);
    const isLoading = ref(false);
    const isTagEditorOpen = ref(false);
    const isCorporateAIEditorOpen = ref(false);
    const isBpmnModelerOpen = ref(false);
    const isSettingsOpen = ref(false);
    const isFilesModalOpen = ref(false);
    const isViewerOpen = ref(false);
    const settings = ref<Settings>({
      openAiApiKey: '',
      chatModel: 'gpt-4o-mini',
      realtimeModel: 'gpt-4o-realtime-preview-2024-10-01',
    });
    const messageDict = ref<{ [key: number]: Message }>({});
    const sessionData = ref('');
    const taskContext = ref('');
    const longPlainTextResponse = ref('');

    const auth = useAuthStore();
    const gameService = GameStreamingService.getInstance();
    console.log('DevChat initializing ProcessorService with token (length:', auth.token.length, ')');
    const chatService = new ProcessorService(auth.token, gameService, auth.isAutomabMode);

    const audioService = RealtimeAudioStreamingService.getInstance();

    let automabService: AutomabService | null = null;

    const availableServers = ref<string[]>([]);
    const isAdmin = ref(false);

    const viewerSearchString = ref('');

    const currentItemIdByMsgType: Record<string, string | null> = {
      client: null,
      PrimaryAI: null,
      ObserverAI: null
    };

    const hasUnsavedChanges = ref(false);

    const configEditorModal = ref<InstanceType<typeof ConfigEditorModal> | null>(null);

    const filteredChatStack = computed(() => {
      return chatStack.value.map(conversation => {
        if (!conversation) return [];
        try {
          return filterMessages(conversation || [], activeFilter.value, activeFilterType.value);
        } catch (error) {
          console.error('Error filtering messages:', error);
          return [];
        }
      }).filter(Boolean);
    });

    const showTriggerSystem2Button = computed(() => {
      return false;
    });

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
      localStorage.setItem('sidebarState', isMenuOpen.value ? 'open' : 'closed');
    };

    const toggleFilter = (event: Event) => {
      event.stopPropagation();
      showFilter.value = !showFilter.value;
    };

    const applySimpleFilter = async (filter: string) => {
      activeFilterType.value = "simple";
      activeFilter.value = filter;
    };

    const applyMongoFilter = async (filter: string) => {
      activeFilterType.value = "mongo";
      activeFilter.value = filter;
    };

    const router = useRouter();

    const handleLogout = async () => {
      cleanupFn();
      auth.clearAuth();
      router.push('/login');
    };

    const handleShare = async () => {
      const baseUrl = window.location.href.split('?')[0];
      const shareUrl = `${baseUrl}?token=${auth.token}`;

      navigator.clipboard.writeText(shareUrl).then(() => {
        $toast.success('Share link copied to clipboard', {
          position: 'top-right',
          duration: 3000,
        });
      }).catch(err => {
        console.error('Failed to copy URL:', err);
        $toast.error('Failed to copy share link', {
          position: 'top-right',
          duration: 3000
        });
      });
    };

    const handleGenerateFilter = (prompt: string, callback: (filter: string, filterType: string) => void) => {
      console.log('Generating filter for prompt:', prompt);

      chatService.handleNaturalLangQuery(prompt).then((response) => {
        if (response.problems) {
          console.error('Error generating filter:', response.problems);
          $toast.error(`Problems generating filter: ${response.problems}`, {
            position: 'top-right',
            duration: 3000
          });
          return;
        }

        if (response.query && response.query.length > 0) {
          activeFilterType.value = "mongo";
          activeFilter.value = response.query;

          console.log('Generated filter:', activeFilter.value);

          callback(activeFilter.value, "mongo");

          $toast.success('Filter generated successfully', {
            position: 'top-right',
            duration: 3000
          });
        }
      }).catch((error) => {
        console.error('Error generating filter:', error);
        $toast.error('Failed to generate filter', {
          position: 'top-right',
          duration: 3000
        });
      });
    };

    const fetchAllMessages = async () => {
      if (!chatStack.value[0]) {
        chatStack.value[0] = [];
      }
      if (chatService) {
        try {
          const messages = await chatService.getAllMessages();
          if (messages && Array.isArray(messages)) {
            chatStack.value[0] = messages;

            messageDict.value = messages.reduce((dict, message) => {
              if (message && message.id !== undefined) {
                dict[message.id] = message;
              } else {
                console.error('Message or Message ID is missing:', message);
              }
              return dict;
            }, {} as { [key: number]: Message });

            console.log('Fetched all messages:', chatStack.value[0]);
            console.log('Constructed message dictionary:', messageDict.value);
          } else {
            console.error('Invalid messages data received:', messages);
            $toast.error('Invalid data received while fetching messages', {
              position: 'top-right',
              dismissible: true,
              duration: 0
            });
          }
        } catch (error) {
          console.error('Error fetching messages:', error);
          $toast.error((error as Error).message || 'An error occurred while fetching messages', {
            position: 'top-right',
            dismissible: true,
            duration: 0
          });
        }
      }
    };

    const sendMessage = async (message: string, depth = 0) => {
      if (message.trim()) {
        try {
          audioService.submitText(message);
        } catch (error) {
          console.error('Error in audioService.submitText:', error);
          $toast.error((error as Error).message || 'audioService.submitText failed', {
            position: 'top-right',
            dismissible: true,
            duration: 0
          });
        }
      }
    };

    const editTags = () => {
      isMenuOpen.value = false;
      isTagEditorOpen.value = true;
    };

    const closeTagEditor = () => {
      isTagEditorOpen.value = false;
    };

    const editCorporateAI = () => {
      isMenuOpen.value = false;
      isCorporateAIEditorOpen.value = true;
    };

    const closeCorporateAIEditor = () => {
      isCorporateAIEditorOpen.value = false;
    };

    const openBpmnModeler = () => {
      isBpmnModelerOpen.value = true;
      isConfigEditorOpen.value = true;
    };

    const closeBpmnModeler = () => {
      isBpmnModelerOpen.value = false;
    };

    const reset = async () => {
      try {
        await chatService.resetChat();
        currentItemIdByMsgType.client = null;
        currentItemIdByMsgType.PrimaryAI = null;
        currentItemIdByMsgType.ObserverAI = null;
        await fetchAllMessages();
      } catch (error) {
        console.error('Failed to reset chat:', error);
        $toast.error((error as Error).message || 'An error occurred while resetting the chat', {
          position: 'top-right',
          dismissible: true,
          duration: 0
        });
      }
    };

    const openSettings = () => {
      isSettingsOpen.value = true;
    };

    const closeSettings = () => {
      isSettingsOpen.value = false;
    };

    const saveSettings = (newSettings: Settings) => {
      localStorage.setItem('settings', JSON.stringify(newSettings));
      settings.value = newSettings;
      chatService.setSettings(settings.value);
      closeSettings();
    };

    const handlePair = async (code: string) => {
      // Check if newPairCode is exactly 6 digits
      if (/^\d{6}$/.test(code)) {
        try {
          const message = await chatService.pairTablet(code);
          $toast.success(message.message, {
            position: 'top-right',
            duration: 3000
          });
        } catch (error) {
          console.error('Failed to pair device:', error);
          $toast.error((error as Error).message || 'Failed to pair device', {
            position: 'top-right',
            dismissible: true,
            duration: 0
          });
        }
      } else {
        $toast.error('Invalid device code', {
          position: 'top-right',
          dismissible: true,
          duration: 0
        });
      }
    };

    const openFilesModal = () => {
      isFilesModalOpen.value = true;
    };

    const closeFilesModal = () => {
      isFilesModalOpen.value = false;
    };

    const openTraceInfo = (message: Message, depth: number) => {
      if (message.traceInfo && message.traceInfo.length) {
        chatStack.value.splice(depth + 1);
        const traceMessages = message.traceInfo.map(id => messageDict.value[id]).filter(msg => msg !== undefined);
        chatStack.value.push(traceMessages);
      }
    };

    const closeTraceInfo = (depth: number) => {
      if (depth > 0) {
        chatStack.value.splice(depth);
      }
    };

    const triggerSystem2 = async () => {
      try {
        isLoading.value = true;
        await chatService.triggerSystem2();
        await fetchAllMessages();
      } catch (error) {
        $toast.error((error as Error).message || 'An error occurred while triggering System 2 thinking', {
          position: 'top-right',
          dismissible: true,
          duration: 0
        });
      } finally {
        isLoading.value = false;
      }
    };

    const fetchServers = async () => {
      try {
        let servers = await chatService.listServers();
        availableServers.value = servers;
        isAdmin.value = servers.length > 0;
      } catch (error) {
        console.error('Failed to list servers:', error);
      }
    };

    const switchServer = async (newServer: string) => {
      try {
        await chatService.switchServer(newServer);

        const url = new URL(window.location.href);
        url.searchParams.set('token', auth.token);
        window.location.href = url.toString();
      } catch (error) {
        console.error('Error switching server:', error);
        $toast.error('Failed to switch server', {
          position: 'top-right',
          dismissible: true,
          duration: 3000,
        });
      }
    };

    const handleSpeechMessage = (source: string, text: string, thisMessageItemId: string) => {
      console.log('Speech message received:', { 
        source, 
        text, 
        thisMessageItemId, 
        currentId: currentItemIdByMsgType[source] 
      });
      
      const msgType = source === 'client' ? "PlayerInput" : "AIResponse";
      
      if (currentItemIdByMsgType[source] === thisMessageItemId) {
        console.log(`De-duping for source ${source}, messageId ${thisMessageItemId}`);
        const messages = chatStack.value[0];
        let found = false;
        
        for (let i = messages.length - 1; i >= 0; i--) {
          const message = messages[i];
          console.log(`Checking message:`, {
            index: i,
            messageSource: message.source,
            messageText: message.text
          });
          
          if (message.source === source) {
            found = true;
            console.log(`Updating existing message at index ${i}`);
            message.text = text;
            message.timestamp = new Date();
            // Only move if not already last
            if (i !== messages.length - 1) {
              console.log(`Moving message from index ${i} to end`);
              messages.splice(i, 1);
              messages.push(message);
            }
            break;  // Exit loop after finding and updating
          }
        }
        
        if (found) {
          console.log('Message updated and repositioned');
          return;  // Exit function after successful update
        }
      }

      const speechMessage: Message = {
        id: -1,
        text: text,
        msgType,  // For positioning
        source,   // For de-duplication
        srcContext: `FE::audioService.speechMessage::${source}`,
        timestamp: new Date()
      };

      console.log('Creating new message:', speechMessage);

      if (!chatStack.value[0]) {
        chatStack.value[0] = [];
      }
      chatStack.value[0].push(speechMessage);
      currentItemIdByMsgType[source] = thisMessageItemId || null;
    };

    const handleReset = () => {
      console.log('Before reset:', window.location.href);
      const url = new URL(window.location.href);

      url.searchParams.set('token', auth.token);
      console.log('After setting token:', url.toString());

      window.location.href = url.toString();
    };

    const automabTabs = ref<AutomabTab[]>([{ name: 'Tab 1', shadowRoot: null }]);
    const activeTabIndex = ref(0);
    const tabRefs = ref<(HTMLElement | null)[]>([]);
    const tabInputRefs = ref<{ [key: number]: HTMLInputElement | null }>({});

    const setTabRef = (el: Element | ComponentPublicInstance | null, index: number) => {
      if (el && !automabTabs.value[index].shadowRoot) {
        const domElement = (el as ComponentPublicInstance)?.$el || el;

        if (domElement instanceof HTMLElement) {
          automabTabs.value[index].shadowRoot = domElement.attachShadow({ mode: 'open' });
        }
      }
    };

    const addNewTab = () => {
      const newIndex = automabTabs.value.length + 1;
      automabTabs.value.push({ name: `Tab ${newIndex}`, shadowRoot: null });
      activeTabIndex.value = automabTabs.value.length - 1;
    };

    const closeTab = (index: number) => {
      if (automabTabs.value.length > 1) {
        automabTabs.value.splice(index, 1);
        if (activeTabIndex.value >= automabTabs.value.length) {
          activeTabIndex.value = automabTabs.value.length - 1;
        }
      }
    };

    const switchTab = (index: number) => {
      activeTabIndex.value = index;
    };

    const shadowUpdate = (html: string) => {
      const currentTab = automabTabs.value[activeTabIndex.value];
      if (currentTab && currentTab.shadowRoot) {
        currentTab.shadowRoot.innerHTML = html;
      }
    };

    const isAutomabVisible = computed(() => auth.isAutomabMode);

    const editingTabIndex = ref<number>(-1);
    const tabNameInput = ref<HTMLInputElement | null>(null);

    const setTabInputRef = (el: Element | ComponentPublicInstance | null, index: number) => {
      if (el instanceof HTMLInputElement) {
        tabInputRefs.value[index] = el;
      }
    };

    const startEditing = (index: number) => {
      editingTabIndex.value = index;
      nextTick(() => {
        const input = tabInputRefs.value[index];
        if (input) {
          input.focus();
        }
      });
    };

    const finishEditing = () => {
      editingTabIndex.value = -1;
    };
    
    const getUpstreamPrimaryStatusIcon = computed(() => {
        switch(audioService.getLastKnownUpstreamStatusPrimary()) {
            case UpstreamState.NO_CONNECTION:
                return upstm_disconnected;
            case UpstreamState.CONN_BIDIR:
                return upstm_conn_bidir;
            case UpstreamState.CONN_MONOLOG:
                return upstm_conn_monolog;
            default:
                return upstm_disconnected; // Default fallback
        }
    });

    const getUpstreamPrimaryStatusIconTitle = computed(() => {
        switch(audioService.getLastKnownUpstreamStatusPrimary()) {
            case UpstreamState.NO_CONNECTION:
                return 'No Connection';
            case UpstreamState.CONN_BIDIR:
                return 'Connected (Bidirectional)';
            case UpstreamState.CONN_MONOLOG:
                return 'Connected (Monolog)';
            default:
                return 'No Connection'; // Default fallback
        }
    });

    const getUpstreamObserverStatusIcon = computed(() => {
        switch(audioService.getLastKnownUpstreamStatusObserver()) {
            case UpstreamState.NO_CONNECTION:
                return upstm_disconnected;
            case UpstreamState.CONN_BIDIR:
                return upstm_conn_bidir;
            case UpstreamState.CONN_MONOLOG:
                return upstm_conn_monolog;
            default:
                return upstm_disconnected; // Default fallback
        }
    });

    const getUpstreamObserverStatusIconTitle = computed(() => {
        switch(audioService.getLastKnownUpstreamStatusObserver()) {
            case UpstreamState.NO_CONNECTION:
                return 'No Observer Connection';
            case UpstreamState.CONN_BIDIR:
                return 'Observer Connected (Bidirectional)';
            case UpstreamState.CONN_MONOLOG:
                return 'Connected (Monolog)'; // will never happen
            default:
                return 'No Observer Connection'; // Default fallback
        }
    });

    const cycleIconState = () => {
      audioService.toggleMonologMode();
    };

    const selectedBpmnNode = ref<BpmnNode | null>(null);

    const handleBpmnNodeSelected = (node: BpmnNode | null) => {
      selectedBpmnNode.value = node;
    };

    const handleBpmnNodeDeleted = (deletedNode: { id: string }) => {
      console.log('Node was deleted in DevChat:', deletedNode);
      configEditorModal.value?.removeConfigForNodeId(deletedNode.id);
    };

    const onConfigChanged = () => {
      hasUnsavedChanges.value = true;
    };

    const handleBpmnSave = async () => {
      configEditorModal.value?.saveConfig();
      hasUnsavedChanges.value = false;
    };

    const automabHtmlContent = ref<string>('');

    const onModelChanged = () => {
      hasUnsavedChanges.value = true;
    };

    // 2) Called when we get a "factory-reset" event from BpmnModeler
    function handleFactoryReset() {
      console.log('[DevChat] handleFactoryReset called');
      configEditorModal.value?.loadDefaultConfig();
    }

    const handleAttachment = async (fileData: { name: string, content: ArrayBuffer, size: number }) => {
      console.log('Attached PDF file:', fileData.name);
      console.log('File size:', fileData.size, 'bytes');
      const buff = Buffer.from(fileData.content);
      
      try {
        const response = await chatService.handleAttachment(fileData.name, buff.toString('base64'));
        const task_id = response.task_id;
        console.log("Task ID received:", task_id);

        let lastStatus = '';
        
        // Start polling
        const pollStatus = async () => {
          const statusResponse = await chatService.pdfTaskStatus(task_id);
          
          if (statusResponse.error) {
            $toast.error(`PDF Processing Error: ${statusResponse.status}`, {
              position: 'top-right',
              duration: 20000,
            });
            return; // Stop polling on error
          }

          if (statusResponse.finished) {
            $toast.success(`PDF Processing Complete: ${statusResponse.status}`, {
              position: 'top-right',
              duration: 10000,
            });
            return; // Stop polling when finished
          }

          // Show toast if status changed
          if (statusResponse.status !== lastStatus) {
            lastStatus = statusResponse.status;
            $toast.info(`PDF Processing: ${statusResponse.status}`, {
              position: 'top-right',
              duration: 5000,
            });
          }

          // Continue polling every 10 seconds if not finished and no error
          setTimeout(pollStatus, 10000);
        };

        // Start the first poll
        pollStatus();

      } catch (error) {
        console.error('Error handling PDF attachment:', error);
        $toast.error(`Failed to process PDF: ${(error as Error).message}`, {
          position: 'top-right',
          duration: 5000,
        });
      }
    };

    const cycleObserverIconState = () => {
        audioService.toggleObserverMode();
    };

    const cleanupFn = () => {
      // Clean up all services in a consistent order
      if (automabService) {
        automabService.disconnect();
      }
      GameStreamingService.reset();
      RealtimeAudioStreamingService.destroyInstance().catch(console.error);
    };

    onMounted(async () => {
      const savedSettings = localStorage.getItem('settings');
      if (savedSettings) {
        settings.value = JSON.parse(savedSettings);
      }
      
      // API key is now optional (server-side default used if not set)
      
      try {
        await chatService.init();
        chatService.setSettings(settings.value);
        chatService.resetChat();
      } catch (error) {
        console.error("Failed to initialize chat service:", error);
        // Show toast but don't redirect
        $toast.error("Chat service initialization failed. API key may be missing.", {
          position: 'top-right',
          duration: 5000,
        });
        // Continue without redirecting
      }

      await fetchAllMessages();
      await fetchServers();

      audioService.onSpeechMessage((source: string, text: string, itemId: string) => {
        handleSpeechMessage(source, text, itemId);
      });

      gameService.onStringMessage((typeCase: ToGameClient.TypeCase, message: string) => {
        switch (typeCase) {
          case ToGameClient.TypeCase.TASK_START:
            taskContext.value = message;
            console.log(`[DevChat] onStringMessage TASK_START: ${message}`);
            break;
          case ToGameClient.TypeCase.TASK_COMPLETE:
            fetchAllMessages();
            console.log(`[DevChat] onStringMessage TASK_COMPLETE: ${message}`);
            break;
          default:
            console.error(`[DevChat] onStringMessage *UNHANDLED*: ${typeCase}, ${message}`);
            break;
        }
      });

      gameService.onSessionDataUpdate((data: string) => {
        shadowUpdate(data);
      });

      if (auth.isAutomabMode) {
        console.log('[DevChat] Testing Automab connection...');
        automabService = await AutomabService.getInstance();

        automabService.onMessage((message: any) => {
          if (message.getType() === 'generate_html_form') {
            const htmlForm = message.getPayload();
            shadowUpdate(htmlForm);
          } else if (message.getType() === '3DViewer') {
            console.log('[DevChat] Received 3DViewer message');
            const action = message.getPayload();
            if (action === 'show') {
              isViewerOpen.value = true;
            } else if (action === 'hide') {
              isViewerOpen.value = false;
            } else {
              isViewerOpen.value = !isViewerOpen.value;
            }
          } else if (message.getType() === '3DSearch') {
            console.log('[DevChat] Received 3DSearch message');
            const searchString = message.getPayload();
            viewerSearchString.value = searchString;
            isViewerOpen.value = true;
          } else if (message.getType() === 'update_devchat_css') {
            console.log('[DevChat] Received update_devchat_css message');
            const cssContent = message.getPayload();

            const styleElement = document.createElement('style');
            styleElement.appendChild(document.createTextNode(cssContent));
            document.head.appendChild(styleElement);

            const toastMessage = 'New CSS received <button onclick="window.__copyCssToClipboard()" style="margin-left: 10px; padding: 2px 8px; border-radius: 4px;">Copy to Clipboard</button>';

            $toast.info(toastMessage, {
              position: 'top-right',
              duration: 5000,
              dismissible: true
            });

            (window as any).__copyCssToClipboard = async () => {
              try {
                await navigator.clipboard.writeText(cssContent);
                $toast.success('CSS copied to clipboard', {
                  position: 'top-right',
                  duration: 3000,
                });
              } catch (err) {
                console.error('Failed to copy CSS:', err);
                $toast.error('Failed to copy CSS to clipboard', {
                  position: 'top-right',
                  duration: 3000,
                });
              }
            };
          }
        });

        automabService.onSessionUpdate((data: string) => {
          console.log('[DevChat] Received session data:', sessionData);
          shadowUpdate(data);
        });
      }

      window.addEventListener('beforeunload', cleanupFn);
    });

    onUnmounted(() => {
      window.removeEventListener('beforeunload', cleanupFn);
      cleanupFn();
    });

    const isRecording = computed(() => audioService.isRecording());
    const isMicMuted = computed(() => audioService.isMicMuted());
    const isPlayingBack = computed(() => audioService.isPlayingBack());
    const isConfigEditorOpen = ref(false);

    const openConfigEditor = () => {
      isConfigEditorOpen.value = true;
    };

    const closeConfigEditor = () => {
      isConfigEditorOpen.value = false;
    };

    const openViewer = () => {
      isViewerOpen.value = true;
    };

    const closeViewer = () => {
      isViewerOpen.value = false;
    };

    const openGame = () => {
      window.location.href = '/game?token=' + auth.token + '&apiKey=' + settings.value.openAiApiKey;
      //console.log(auth.token);
    };

    return {
      chatStack,
      isRecording,
      isMicMuted,
      isPlayingBack,
      toggleAudioMuting: () => audioService.toggleAudioMuting(),
      activeFilter,
      activeFilterType,
      showFilter,
      filteredChatStack,
      filterContainer,
      isMenuOpen,
      isLoading,
      isTagEditorOpen,
      isCorporateAIEditorOpen,
      isBpmnModelerOpen,
      isSettingsOpen,
      isFilesModalOpen,
      isViewerOpen,
      settings,
      messageDict,
      chatService,
      showTriggerSystem2Button,
      toggleMenu,
      toggleFilter,
      applySimpleFilter,
      applyMongoFilter,
      handleGenerateFilter,
      sendMessage,
      editTags,
      closeTagEditor,
      editCorporateAI,
      closeCorporateAIEditor,
      openBpmnModeler,
      closeBpmnModeler,
      reset,
      openSettings,
      closeSettings,
      closeViewer,
      saveSettings,
      handlePair,
      openFilesModal,
      closeFilesModal,
      openTraceInfo,
      closeTraceInfo,
      triggerSystem2,
      handleLogout,
      handleShare,
      availableServers,
      isAdmin,
      switchServer,
      handleSpeechMessage,
      handleReset,
      sessionData,
      taskContext,
      isConfigEditorOpen,
      openConfigEditor,
      closeConfigEditor,
      automabHtmlContent,
      openViewer,
      openGame,
      isAutomabVisible,
      viewerSearchString,
      longPlainTextResponse,
      micOnIcon,
      micOffIcon,
      filterIcon,
      noFilterIcon,
      automabTabs,
      activeTabIndex,
      addNewTab,
      closeTab,
      switchTab,
      setTabRef,
      editingTabIndex,
      setTabInputRef,
      startEditing,
      finishEditing,
      getCurrentIcon: getUpstreamPrimaryStatusIcon,
      getIconTitle: getUpstreamPrimaryStatusIconTitle,
      getObserverIcon: getUpstreamObserverStatusIcon,
      getObserverIconTitle: getUpstreamObserverStatusIconTitle,
      cycleIconState,
      selectedBpmnNode,
      handleBpmnNodeSelected,
      configEditorModal,
      handleBpmnNodeDeleted,
      hasUnsavedChanges,
      onConfigChanged,
      handleBpmnSave,
      onModelChanged,
      handleFactoryReset,
      handleAttachment,
      cycleObserverIconState,
      userName: computed(() => auth.userName),
      userEmail: computed(() => auth.userEmail),
      auth,
    };
  },
});
</script>

<style scoped>
.sidebar-header {
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-logo {
  width: 36px;
  height: 36px;
  object-fit: contain;
}

.automab-tabs-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tabs-header {
  display: flex;
  background: #f5f5f5;
  border-bottom: 1px solid #ddd;
  padding: 8px 8px 0 8px;
}

.tab-button {
  padding: 8px 16px;
  background: #e9e9e9;
  border: 1px solid #ddd;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  margin-right: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.tab-button.active {
  background: white;
  border-bottom: 1px solid white;
  margin-bottom: -1px;
}

.close-tab {
  background: none;
  border: none;
  padding: 0 4px;
  cursor: pointer;
  font-size: 16px;
}

.add-tab {
  padding: 4px 12px;
  background: #e9e9e9;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 4px;
}

.tabs-content {
  flex: 1;
  position: relative;
}

.automab-html {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.automab-html.active {
  display: block;
}

.tab-name-input {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-family: inherit;
  width: 80px;
}

.tab-button span {
  min-width: 60px;
  display: inline-block;
}

.side-by-side-container {
  position: fixed;
  top: 0;
  left: 50px;
  right: 0;
  bottom: 0;
  display: flex;
  gap: 8px;
  padding: 8px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  transition: left 0.3s ease;
}

.side-by-side-container.sidebar-open {
  left: 50px;
}

.side-panel {
  flex: 1;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.observer-button {
  background-color: #ff0000;  /* Bright red */
  border: none;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.observer-button:hover {
  background-color: #cc0000;  /* Darker red on hover */
}
</style>
