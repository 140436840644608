import { AIService } from "./AIService";
import { Message, Settings, TagSet, CorpSet } from "./types";
import { GameStreamingService } from './GameManager';

export class ProcessorService implements AIService {
    private static configPromise: Promise<any> | null = null;
    public apiUrl: string;
    public token: string;
    private settings: Settings;
    private gameService: GameStreamingService;
    private automab = false;

    constructor(token: string, gameService: GameStreamingService, automab = false) {
        this.apiUrl = "not initialized";
        this.token = token;
        this.settings = { openAiApiKey: "", chatModel: "gpt-4o-mini", realtimeModel: "gpt-4o-realtime-preview-2024-10-01" };
        this.gameService = gameService;
        this.automab = automab;
    }

    async init() {
        await this.loadConfig();
    }

    private static async getConfig() {
        if (!this.configPromise) {
            this.configPromise = fetch('/devconfig.json').then(response => response.json());
        }
        return this.configPromise;
    }

    public static async getHttpUrl(isAutomab = false): Promise<string> {
        const pathname = isAutomab ? '/automab' : '/api';
        const hostname = window.location.hostname;
        try {
            if (hostname === "localhost" || hostname === "127.0.0.1" || hostname === "::1") {
                return `${window.location.protocol}//${hostname}:3003/api`;
            } else {
                return `${window.location.origin}${pathname}`;
            }
        } catch (error) {
            console.error('Failed to load config:', error);
            return pathname;
        }
    }

    private async loadConfig() {
        this.apiUrl = await ProcessorService.getHttpUrl(this.automab);
        console.log('API URL set to:', this.apiUrl);
    }

    private async apiCall<T>(route: string, data: any = {}, method = 'POST'): Promise<T> {
        try {
            let url = `${this.apiUrl}/${route}`;
            console.log(`[ProcessorService] apiCall ${route} with token (length: ${this.token.length})`);
            const options: RequestInit = {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            };

            if (method !== 'GET') {
                options.body = JSON.stringify({ ...data, token: this.token });
            } else {
                // For GET requests, append token as a query parameter
                url += (url.includes('?') ? '&' : '?') + `token=${encodeURIComponent(this.token)}`;
            }

            const response = await fetch(url, options);
            if (!response.ok) {
                let errorData = await response.json();
                console.log('URL', url, 'FULL errorData', errorData);
                while (errorData.error) {
                    errorData = errorData.error;
                }
                if (typeof errorData === 'string') {
                    errorData = { message: errorData };
                }
                throw new Error(errorData.message || 'An error occurred');
            }
            return await response.json();
        } catch (error) {
            console.error(`Error in ${route} API call:`, error);
            throw error;
        }
    }
    public setSettings(settings: Settings): void {
        this.settings = settings;
    }

    public startSpeechRecognition(): void {
        console.error("Speech api is not implemented.");
    }

    public stopSpeechRecognition(): void {
        console.error("Speech api is not implemented.");
    }

    public speakText(text: string): void {
        console.error("Speech api is not implemented.", text);
    }

    public stopSpeaking(): void {
        console.error("Speech api is not implemented.");
    }

    public async chat(prompt: string): Promise<string> {
        return this.generateResponse(prompt, "processor")
    }

    public async generateResponse(prompt: string, route: string): Promise<string> {
        const data = await this.apiCall<{ message: string }>(route, { prompt: prompt, settings: this.settings });
        return data.message;
    }

    public async triggerSystem2(): Promise<string> {
        const data = await this.apiCall<{ message: string }>("system2", { settings: this.settings });
        return data.message;
    }

    public async getAllMessages(): Promise<Message[]> {
        return await this.apiCall<Message[]>("messages");
    }

    public async resetChat(): Promise<void> {
        const resetAck = await this.gameService.sendAndWaitForResponse();
        console.log('resetAck rxd', resetAck);
    }

    public async getTags(): Promise<{ tagSet: TagSet[] }> {
        return await this.apiCall<{ tagSet: TagSet[] }>("get_tags");
    }

    public async setTags(tagSet: TagSet[]): Promise<void> {
        await this.apiCall<{ message: string }>("set_tags", { tagSet });
    }

    public async getDefaultTags(): Promise<{ tagSet: TagSet[] }> {
        return await this.apiCall<{ tagSet: TagSet[] }>("get_default_tags");
    }

    public async getCorporateAI(): Promise<{ corporateAI: CorpSet }> {
        return await this.apiCall<{ corporateAI: CorpSet }>("get_corporate_ai");
    }

    public async getProcessModelXml(): Promise<{ processModelXml: string }> {
        return await this.apiCall<{ processModelXml: string }>("get_process_model_xml");
    }

    public async getDefaultProcessModelXml(): Promise<{ processModelXml: string }> {
        return await this.apiCall<{ processModelXml: string }>("get_default_process_model_xml");
    }

    public async setCorporateAI(corporateAI: CorpSet): Promise<void> {
        await this.apiCall<{ message: string }>("set_corporate_ai", { corporateAI });
    }

    public async setProcessModelXml(processModelXml: string): Promise<void> {
        await this.apiCall<{ message: string }>("set_process_model_xml", { processModelXml });
    }

    public async getDefaultCorporateAI(): Promise<{ corporateAI: CorpSet }> {
        return await this.apiCall<{ corporateAI: CorpSet }>("get_default_corporate_ai");
    }

    public async getUserFiles(): Promise<{ files: { id: string; name: string }[] }> {
        return await this.apiCall<{ files: { id: string; name: string }[] }>("user-files", {}, 'GET');
    }

    public async getFileContent(fileId: string): Promise<{ content: string }> {
        return await this.apiCall<{ content: string }>(`user-files/${fileId}/content`, {}, 'GET');
    }

    public async addUserFile(name: string): Promise<void> {
        await this.apiCall<{ message: string }>("user-files", { name }, 'POST');
    }

    public async removeUserFile(fileId: string): Promise<void> {
        await this.apiCall<{ message: string }>(`user-files/${fileId}`, {}, 'DELETE');
    }

    public async renameUserFile(fileId: string, newName: string): Promise<void> {
        await this.apiCall<{ message: string }>(`user-files/${fileId}/rename`, { name: newName }, 'PUT');
    }

    public async saveUserFileContent(fileId: string, content: string): Promise<void> {
        await this.apiCall<{ message: string }>(`user-files/${fileId}/content`, { content }, 'PUT');
    }

    public async generateNewCorp(prompt: string): Promise<CorpSet> {
        return await this.apiCall<CorpSet>("generate_new_corp", { settings: this.settings, prompt: prompt });
    }

    public async listServers(): Promise<string[]> {
        return await this.apiCall<string[]>("server/list");
    }

    public async switchServer(server: string): Promise<string> {
        return await this.apiCall<string>("server/switch", { server });
    }

    public async handleNaturalLangQuery(
        naturalLangQuery: string
    ): Promise<{ query: string, problems: string }> {
        try {
            const result = await this.apiCall<{ result: { query: string, problems: string } }>(
                'handle-natural-lang-query',
                {
                    settings: this.settings,
                    naturalLangQuery
                }
            );

            return result.result;
        } catch (error) {
            console.error('Error in ProcessorService.handleNaturalLangQuery:', error);
            throw new Error('Failed to generate structured output');
        }
    }

    public async getConfigData(): Promise<string> {
        return await this.apiCall<string>(
            'get-config-data',
            {}
        );
    }

    public async getDefaultConfigData(): Promise<string> {
        return await this.apiCall<string>(
            'get-default-config-data',
            {}
        );
    }

    public async saveConfigData(configData: string): Promise<void> {
        await this.apiCall<{ message: string }>(
            'save-config-data',
            { configData },
            'POST'
        );
    }

    public async handleAttachment(fileName: string, fileBytes: string): Promise<{ task_id: string }> {
        return await this.apiCall<{ task_id: string }>(
            'handle_pdf',
            { settings: this.settings, fileName: fileName, fileBytes: fileBytes },
            'POST'
        );
    }

    public async pdfTaskStatus(task_id: string): Promise<{ finished: boolean, error: boolean, status: string }> {
        return await this.apiCall<{ finished: boolean, error: boolean, status: string }>(
            'handle_pdf_status',
            { settings: this.settings, task_id: task_id },
            'POST'
        );
    }

    public async pairTablet(code: string): Promise<{ message: string }> {
        return await this.apiCall<{ message: string }>(
            'pair',
            { settings: this.settings, code: code },
            'POST'
        );
    }

    public static getServerHostPort(): string {
        const hostname = window.location.hostname;
        const isLocalDev = hostname === 'localhost' ||
            hostname === '127.0.0.1' ||
            hostname === '::1';

        const port = isLocalDev ? ':3003' :
            window.location.port ? `:${window.location.port}` : '';

        return `${hostname}${port}`;
    }
}
