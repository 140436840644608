import { createRouter, createWebHistory, RouteRecordRaw, NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { useAuthStore } from '../stores/auth';
import LoginScreen from '../LoginScreen.vue';
import DevChat from '../DevChat.vue';
import { RealtimeAudioStreamingService } from '../RealtimeApiAudioManager';
import GroupMeeting from '../components/GroupMeeting.vue';

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        component: LoginScreen
    },
    {
        path: '/chat',
        component: DevChat
    },
    {
        path: '/meeting',
        component: GroupMeeting
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

// Global navigation guard
router.beforeEach((
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
) => {
    const auth = useAuthStore();

    // Allow login route always
    if (to.path === '/login') {
        next();
        return;
    }

    if (!auth.isAuthenticated) {
        console.log('[Router] Not authenticated, redirecting to login', to.fullPath);
        // Store the attempted route before redirecting
        auth.setOriginalRoute(to.fullPath);
        next('/login');
        return;
    }

    // For chat specifically, verify audio service but don't redirect on failure
    if (to.path === '/chat') {
        try {
            RealtimeAudioStreamingService.getInstance();
        } catch (error) {
            console.log('[Router] Audio service initialization failed:', error);
            // Don't redirect to login, allow user to stay on the chat screen
        }
        next();
        return;
    }

    // Other authenticated routes
    next();
});

export default router;
