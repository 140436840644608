import {
  ToGameServer,
  ToGameClient,
  LoginRequest,
  ResetAck,
  ExerciseSet,
  ExerciseSetReport,
  Status
} from '../proto-gen-ref/game_pb';
import { WebSocketManager } from './WebSocketManager';
import { v4 as uuidv4 } from 'uuid';
import { IGameStreamingService, Settings } from './types';
import { RealtimeAudioStreamingService } from './RealtimeApiAudioManager';
import { ToastPluginApi } from 'vue-toast-notification';
import type { Meeting, MeetingRoute } from './types';
import { useAuthStore } from './stores/auth';
import { AutomabService } from './services/AutomabService';

interface ResponseSettlers {
  resolve: (value: ToGameClient) => void;
  reject: (reason?: any) => void;
  timeout: ReturnType<typeof setTimeout>;
}

// Constants
const TIMEOUT_DURATION = 10000;
const ERROR_MESSAGES = {
  NO_CREQ: 'sendAndWaitForResponse received non-Creq tgs object',
  RESPONSE_TIMEOUT: 'Response timeout',
  LOGIN_FAILED: 'Login failed',
} as const;

export class GameStreamingService extends WebSocketManager implements IGameStreamingService {
  private responseSettlers = new Map<string, ResponseSettlers>();
  private loginCreqId: string | null = null;
  private settings?: Settings;
  private stringMessageCallbacks: Array<(pushCase: ToGameClient.TypeCase, message: string) => void> = [];
  private sessionDataCallbacks: Array<(data: string) => void> = [];

  private constructor(
    wsPathname: string,
    toast: ToastPluginApi,
    userToken: string
  ) {
    super(wsPathname, true, toast, userToken);
  }

  private static instance: GameStreamingService | null = null;
  public static getInstance(): GameStreamingService {
    if (!this.instance) {
      throw new Error('GameStreamingService not initialized');
    }
    return this.instance;
  }

  public static initialize(toast: ToastPluginApi, userToken: string): GameStreamingService {
    const path = '/ws/game';
    if (this.instance) {
      console.error('GameStreamingService already initialized');
    }
    this.instance = new GameStreamingService(path, toast, userToken);
    return this.instance;
  }

  public static reset(): void {
    if (this.instance) {
      this.instance.disconnect();
      this.instance = null;
    }
  }

  setToken(token: string): void {
    this.userToken = token;
    const savedSettings = localStorage.getItem('settings');
    if (savedSettings) {
      this.settings = JSON.parse(savedSettings);
      console.log('[GameManager] Loaded settings from localStorage');
    } else {
      console.warn('[GameManager] No settings found in localStorage');
    }
  }

  private getAndRemoveSettlers(creqId: string): Omit<ResponseSettlers, 'timeout'> | undefined {
    const settlers = this.responseSettlers.get(creqId);
    if (settlers) {
      clearTimeout(settlers.timeout);
      this.responseSettlers.delete(creqId);
      const { resolve, reject } = settlers;
      return { resolve, reject };
    }
    return undefined;
  }

  private setupRequest(creqId: string): Promise<ToGameClient> {
    console.log('[GameManager] setupRequest', creqId);

    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        if (this.responseSettlers.has(creqId)) {
          console.warn('Request timed out', creqId);
          this.getAndRemoveSettlers(creqId);
          reject(new Error(ERROR_MESSAGES.RESPONSE_TIMEOUT));
        }
      }, TIMEOUT_DURATION);

      this.responseSettlers.set(creqId, { resolve, reject, timeout });
    });
  }

  async sendAndWaitForResponse(): Promise<ResetAck> {
    if (!this.isConnected()) {
      await this.connect();
    }

    const creqId = uuidv4();
    console.log('[GameManager] sendAndWaitForResponse', creqId);
    const tgs = new ToGameServer()
      .setResetReq(creqId);

    const responsePromise = this.setupRequest(creqId);
    this.wsConnection!.send(tgs.serializeBinary());
    const response = await responsePromise;
    return response.getResetAck()!;
  }

  protected sendConnectRequest(): void {
    const creqId = uuidv4();
    this.loginCreqId = creqId;
    console.log('[GameManager] sendConnectRequest', creqId);
    const loginReq = new LoginRequest()
      .setCreqId(creqId)
      .setToken(this.userToken);

    if (this.settings) {
      loginReq.setOpenaiApiKey(this.settings.openAiApiKey);
      loginReq.setChatModel(this.settings.chatModel);
      loginReq.setRealtimeModel(this.settings.realtimeModel);
      console.log('[GameManager] Including settings in login request');
    } else {
      console.warn('[GameManager] No settings available for login request');
    }

    const tgs = new ToGameServer()
      .setLoginReq(loginReq);

    this.setupRequest(creqId);
    this.wsConnection?.send(tgs.serializeBinary());
  }

  protected handleMessage(event: MessageEvent): void {
    console.log('[GameManager] handleMessage');
    const tgc = ToGameClient.deserializeBinary(new Uint8Array(event.data));
    const typeCase = tgc.getTypeCase();

    const handlers: Record<ToGameClient.TypeCase, () => Promise<void>> = {
      [ToGameClient.TypeCase.TYPE_NOT_SET]: () => Promise.resolve(),
      [ToGameClient.TypeCase.SPSH_NAMED_MSG_VALS]: () => Promise.resolve(),
      [ToGameClient.TypeCase.SPSH_NAMED_VALS]: () => Promise.resolve(),
      [ToGameClient.TypeCase.SESSION_DATA]: async () => {
        this.handleSessionData(tgc);
      },
      [ToGameClient.TypeCase.TASK_START]: async () => {
        const msg = tgc.getTaskStart();
        console.log('[GameManager] Handling TASK_START for: ', msg);
        this.notifyStringMessageSubscribers(typeCase, msg?.toString() || '');
      },
      [ToGameClient.TypeCase.TASK_COMPLETE]: async () => {
        const msg = tgc.getTaskComplete();
        console.log('[GameManager] Handling TASK_COMPLETE for: ', msg);
        this.notifyStringMessageSubscribers(typeCase, msg?.toString() || '');
      },
      [ToGameClient.TypeCase.EXERCISE_START]: async () => {
        const msg = tgc.getExerciseStart();
        console.log('[GameManager] Handling EXERCISE_START for: ', msg);
        // this.notifyStringMessageSubscribers(typeCase, msg?.toString() || '');
      },
      [ToGameClient.TypeCase.SET_START]: async () => {
        const msg = tgc.getSetStart();
        console.log('[GameManager] Handling SET_START for: ', msg);
        // this.notifyStringMessageSubscribers(typeCase, msg?.toString() || '');
      },
      [ToGameClient.TypeCase.LOGIN_ACK]: async () => {
        await this.handleLoginAck(tgc);
      },
      [ToGameClient.TypeCase.RESET_ACK]: async () => {
        this.handleResetAck(tgc);
      },
      [ToGameClient.TypeCase.AUDIO_LISTEN_RESP]: async () => {
        const response = tgc.getAudioListenResp();
        console.log('[GameManager] Handling AUDIO_LISTEN_RESP: ', response);
        if (response) {
          this.notifyStringMessageSubscribers(typeCase, JSON.stringify({
            requestId: response.getRequestId(),
            success: response.getSuccess(),
            error: response.getError()
          }));
        }
      },
      [ToGameClient.TypeCase.ACTIVITY_INVOKE]: async () => {
        const activityInvoke = tgc.getActivityInvoke();
        console.log('[GameManager] Handling ACTIVITY_INVOKE: ', activityInvoke);
        if (activityInvoke) {
          this.notifyStringMessageSubscribers(typeCase, activityInvoke.getActivityId());
        }
      },
    };

    if (handlers[typeCase]) {
      handlers[typeCase]().catch((error: Error) => {
        console.error('[GameManager] Error handling message:', error);
      });
    } else {
      console.warn('[GameManager] No handler for message type:', typeCase);
    }
  }

  private generateWorkoutSummary = (tgc: ToGameClient) => {
    const summary = ['<div class="workout-summary">'];

    const sessionData = tgc.getSessionData()!;
    const status = this.statusToString(sessionData.getStatus());
    summary.push(`<h3>Workout Status: ${status}</h3>`);

    sessionData.getExercisesList().forEach((exercise, exerciseIndex) => {
      const exerciseName = exercise.getName();
      summary.push(`<div class="exercise">
        <h4>Exercise ${exerciseIndex + 1}: ${exerciseName}</h4>
        <div class="sets">`);

      exercise.getSetsList().forEach((set, setIndex) => {
        const plannedReps = set.getPlannedReps();
        const actualReps = set.getActualReps();
        const status = this.statusToString(set.getStatus());
        const restTime = set.getRestTimeSeconds();
        summary.push(
          `<div class="set">
            Set ${setIndex + 1}: Planned ${plannedReps}, Actual ${actualReps}, Status: ${status}, Rest Time: ${restTime} seconds
          </div>`
        );
      });

      summary.push('</div></div>'); // Close sets and exercise divs
    });

    summary.push('</div>'); // Close workout-summary div
    return summary.join("\n");
  };

  private statusToString(status: Status): string {
    switch (status) {
      case Status.NOTSTARTED:
        return "Not Started";
      case Status.INPROGRESS:
        return "In Progress";
      case Status.COMPLETED:
        return "Completed";
      default:
        return "Unknown";
    }
  }

  private handleSessionData(tgc: ToGameClient): void {
    const sessionData = tgc.getSessionData()!;
    console.log('[GameManager] Received SessionData:', sessionData);

    const sessionDataString = JSON.stringify({
      status: sessionData.getStatus(),
      currentExerciseIndex: sessionData.getCurrentExerciseIndex(),
      exercises: sessionData.getExercisesList().map(exercise => ({
        name: exercise.getName(),
        currentSetIndex: exercise.getCurrentSetIndex(),
        sets: exercise.getSetsList().map(set => ({
          status: set.getStatus(),
          plannedReps: set.getPlannedReps(),
          actualReps: set.getActualReps()
        }))
      }))
    }, null, 2);

    const workoutSummary = this.generateWorkoutSummary(tgc);

    this.notifySessionDataSubscribers(workoutSummary);

    const sessionStatus = sessionData.getStatus();
    const currentExercise = sessionData.getExercisesList()[sessionData.getCurrentExerciseIndex()];
    const currentSet = currentExercise.getSetsList()[currentExercise.getCurrentSetIndex()];
    const currentExerciseIndex = sessionData.getCurrentExerciseIndex();
    const currentSetIndex = currentExercise.getCurrentSetIndex();

    // TODO: game would look at the session data and take appropriate action
    // for now, just make like we did the exercise and respond immediately
    // fill out currentSet and send it back
    if (sessionStatus === Status.INPROGRESS) {
      currentSet.setStatus(Status.COMPLETED);
      // update the actuals to equal the planned reps
      currentSet.setActualReps(currentSet.getPlannedReps());
    }

    // fill out the ExerciseSetReport
    const exerciseSetReport = new ExerciseSetReport();
    exerciseSetReport.setExerciseIndex(currentExerciseIndex);
    exerciseSetReport.setSetIndex(currentSetIndex);
    exerciseSetReport.setExerciseSet(currentSet);

    // wait 1 second
    setTimeout(() => {
      // send the updated currentSet back to the server
      this.send(new ToGameServer().setExerciseSetReport(exerciseSetReport));
    }, 1000);
  }

  private notifySessionDataSubscribers(data: string): void {
    this.sessionDataCallbacks.forEach(callback => {
      callback(data);
    });
  }

  public onSessionDataUpdate(callback: (data: string) => void): void {
    this.sessionDataCallbacks.push(callback);
  }

  private handleCommonAck(tgc: ToGameClient, ackType: 'LOGIN_ACK' | 'RESET_ACK', creqId: string, errMsg: string): void {
    const settlers = this.getAndRemoveSettlers(creqId);
    if (!settlers) {
      console.error('[GameManager] response w/o resolver', creqId, errMsg);
      return;
    }

    const { resolve, reject } = settlers;

    if (errMsg.length > 0) {
      console.error(`[GameManager] ${ackType} failed:`, errMsg);

      // Check for token-related errors
      if (errMsg.toLowerCase().includes('invalid token')) {
        // Dispatch event before rejecting the promise
        window.dispatchEvent(new CustomEvent('tokenExpired'));

        // Clean up the connection
        this.disconnect();

        // Clear the instance to allow re-initialization
        GameStreamingService.instance = null;
      }

      reject(new Error(errMsg));
      return;
    }

    console.log(`[GameManager] ${ackType} successful`, creqId);
    resolve(tgc);
  }

  private async handleLoginAck(tgc: ToGameClient): Promise<void> {
    const loginAck = tgc.getLoginAck()!;
    const errMsg = loginAck.getError();
    const creqId = loginAck.getCreqId();
    const appName = loginAck.getAppName();

    console.log(`[GameManager] LOGIN_ACK:`, creqId);
    console.log(`[GameManager] App Name:`, appName);

    if (creqId === this.loginCreqId && errMsg.length === 0) {
      console.log('[GameManager] Login successful, connection acknowledged');
      this.connAckd = true;
      this.loginCreqId = null;

      // Handle basic login acknowledgment first
      this.handleCommonAck(tgc, 'LOGIN_ACK', creqId, errMsg);

      // Store appName in auth store
      const authStore = useAuthStore();
      authStore.setAppName(appName);

      if (appName === 'automab') {
        AutomabService.initialize(
          '/ws/automab',
          this.toast,
          this.userToken
        );
      }

      // Then handle audio setup separately
      const audioConnKey = loginAck.getAudioConnKey();
      if (audioConnKey) {
        const activeUserAudioMeetings = loginAck.getActiveUserAudioMeetingsList().map(uas => ({
          description: uas.getDescription(),
          meetingId: uas.getUserAudioMeetingId(),
          route: uas.getFeRoute() as '/chat' | '/meeting'
        }));

        await this.setupAudioConnection(audioConnKey, activeUserAudioMeetings).catch(error => {
          console.error('[GameManager] Failed to establish audio connection:', error);
          this.toast.error('Failed to establish audio connection', {
            position: 'top-right',
            dismissible: true,
            duration: 3000,
          });
        });
      }

      return;
    }

    this.handleCommonAck(tgc, 'LOGIN_ACK', creqId, errMsg);
  }

  private async setupAudioConnection(audioConnKey: string, activeUserAudioMeetings: Meeting[]): Promise<void> {
    let connectionEstablished = false;
    while (!connectionEstablished) {
      try {
        console.log('[GameManager] Starting audio setup, active meetings:', activeUserAudioMeetings.length);
        // Check for stored route - only show join menu for /meeting
        const storedRoute = useAuthStore().originalRoute;
        console.log('[GameManager] Stored route:', storedRoute);
        // Default to new chat
        let selectedUserAudioMeetingId = '';
        let route: MeetingRoute = '/chat';
        if (storedRoute === '/meeting') {
          console.log('[GameManager] Showing join menu for meeting');
          const result = await new Promise<{ meetingId: string; route: MeetingRoute }>((resolve) => {
            window.dispatchEvent(new CustomEvent('showJoinMeeting', {
              detail: {
                meetings: activeUserAudioMeetings,
                onSelect: (meetingId: string, selectedRoute: MeetingRoute) => {
                  console.log('[GameManager] Meeting selected:', { meetingId, route: selectedRoute });
                  resolve({ meetingId, route: selectedRoute });
                }
              }
            }));
          });
          selectedUserAudioMeetingId = result.meetingId;
          route = result.route;
        }

        console.log('[GameManager] Initializing audio service with route:', route);
        const audioService = await RealtimeAudioStreamingService.initialize(
          '/ws/audio',
          this.toast,
          this.userToken,
          selectedUserAudioMeetingId,
          route
        );
        console.log('[GameManager] Audio service initialized, calling connectWithKey');
        await audioService.connectWithKey(audioConnKey);
        console.log('[GameManager] Audio service connected successfully');
        connectionEstablished = true;

        // If we get here, connection was successful
        console.log('[GameManager] Audio setup complete, dispatching navigation event with route:', route);
        window.dispatchEvent(new CustomEvent('readyForNavigation', {
          detail: { route }
        }));
      } catch (error) {
        console.error('[GameManager] Failed to establish audio connection:', error);
        this.toast.error('Failed to establish audio connection', {
          position: 'top-right',
          dismissible: true,
          duration: 3000
        });

        // If user cancels or starts new conversation, break the retry loop
        if (!activeUserAudioMeetings.length) {
          break;
        }
        // Otherwise, loop continues and shows join menu again
      }
    }
  }

  private handleResetAck(tgc: ToGameClient): void {
    const resetAck = tgc.getResetAck()!;
    const errMsg = resetAck.getError();
    const creqId = resetAck.getCreqId();
    console.log('[GameManager] RESET_ACK:', creqId);

    this.handleCommonAck(tgc, 'RESET_ACK', creqId, errMsg);
  }

  private notifyStringMessageSubscribers(pushCase: ToGameClient.TypeCase, message: string): void {
    this.stringMessageCallbacks.forEach(callback => {
      callback(pushCase, message);
    });
  }

  public onStringMessage(callback: (pushCase: ToGameClient.TypeCase, message: string) => void): void {
    this.stringMessageCallbacks.push(callback);
  }

  protected onCleanup(): void {
    for (const { timeout } of this.responseSettlers.values()) {
      clearTimeout(timeout);
    }
    this.responseSettlers.clear();
  }

  async send(tgs: ToGameServer): Promise<void> {
    if (!this.isConnected()) {
      await this.connect();
    }

    this.wsConnection!.send(tgs.serializeBinary());
  }

  protected sendKeepAlive(): void {
    const pingMsg = new ToGameServer();
    pingMsg.setPing(Date.now());
    this.wsConnection?.send(pingMsg.serializeBinary());
  }

  protected override connectWebSocketWaitForAck(): Promise<void> {
    const promise = super.connectWebSocketWaitForAck();
    // Start keep-alive as soon as connection is established
    this.startKeepAlive();
    return promise;
  }
}
