import { defineStore } from 'pinia'

const defaultState = {
    token: '',
    userName: '',
    userEmail: '',
    server: '',
    isAuthenticated: false,
    originalRoute: null as string | null,
    appNameInStore: '',
    appNameKnown: false
}

function getAppNameOrThrow(state: { appNameInStore: string; appNameKnown: boolean }): string {
    if (!state.appNameKnown) {
        throw new Error('appName is not known yet.');
    }
    return state.appNameInStore;
}

export const useAuthStore = defineStore('auth', {
    state: () => ({ ...defaultState }),
    actions: {
        setAuth(token: string, user: { name: string; email: string }, server: string) {
            this.token = token;
            this.userName = user.name;
            this.userEmail = user.email;
            this.server = server;
            this.isAuthenticated = true;
        },
        clearAuth() {
            Object.assign(this, defaultState);
        },
        setOriginalRoute(route: string | null) {
            this.originalRoute = route;
        },
        setAppName(appName: string) {
            this.appNameInStore = appName;
            this.appNameKnown = true;
        }
    },
    getters: {
        isAutomabMode: (state) => {
            return getAppNameOrThrow(state) === 'automab';
        },
        isAppNameKnown: (state) => state.appNameKnown,
        appNameOrThrow: (state) => getAppNameOrThrow(state)
    }
}) 