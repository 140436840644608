import { ToastPluginApi } from 'vue-toast-notification';
import { IWebSocketManager } from './types';
import { ProcessorService } from './ProcessorService';

export abstract class WebSocketManager implements IWebSocketManager {
    protected wsConnection: WebSocket | null = null;
    protected connAckd = false;
    protected connectionCounter = 0;
    protected readonly AUTH_KEY = "a692c1cf-c590-49c5-81ef-850afd79d36c";
    private keepAliveInterval: number | null = null;
    private readonly KEEPALIVE_INTERVAL = 20000; // 20 seconds
    private readonly CONNECTION_TIMEOUT = 30000; // 30 seconds

    constructor(
        private readonly wsPathname: string,
        protected readonly waitsForConnAckMsg: boolean,
        protected toast: ToastPluginApi,
        protected userToken: string,
    ) { }

    // Add public methods that wrap the protected ones
    public connect(): Promise<void> {
        return this.connectWebSocketWaitForAck();
    }

    public disconnect(): void {
        this.cleanupWebSocket();
    }

    protected getReadyStateString(state: number): string {
        switch (state) {
            case WebSocket.CONNECTING: return 'CONNECTING';
            case WebSocket.OPEN: return 'OPEN';
            case WebSocket.CLOSING: return 'CLOSING';
            case WebSocket.CLOSED: return 'CLOSED';
            default: return `UNKNOWN(${state})`;
        }
    }
    protected async getWsUrl(): Promise<string> {
        const baseUrl = await ProcessorService.getHttpUrl(false);
        return baseUrl.replace(/^http/, 'ws') + this.wsPathname;
    }
    protected cleanupWebSocket(): void {
        this.stopKeepAlive();
        if (this.wsConnection) {
            const connId = this.connectionCounter;
            console.log(`Cleaning up WebSocket connection #${connId}`, {
                readyState: this.getReadyStateString(this.wsConnection.readyState),
                url: this.wsConnection.url,
                timestamp: new Date().toISOString()
            });

            this.wsConnection.onclose = null;
            this.wsConnection.onerror = null;
            this.wsConnection.onmessage = null;
            this.wsConnection.onopen = null;

            if (this.wsConnection.readyState === WebSocket.OPEN ||
                this.wsConnection.readyState === WebSocket.CONNECTING) {
                this.wsConnection.close();
                console.log(`Explicitly closed WebSocket connection #${connId}`);
            }
            this.wsConnection = null;
        }
        this.connAckd = false;
        this.onCleanup();
    }

    protected abstract onCleanup(): void;
    protected abstract handleMessage(event: MessageEvent): void;
    protected abstract sendConnectRequest(): void;

    protected handleMessageError?(error: unknown): void;

    protected async connectWebSocketWaitForAck(): Promise<void> {
        let isResolved = false; // Flag to track if the promise has been resolved

        try {
            if (this.wsConnection) {
                console.warn('Setting up new WebSocket while previous connection exists', JSON.stringify({
                    existingReadyState: this.getReadyStateString(this.wsConnection.readyState),
                    existingConnId: this.connectionCounter,
                    timestamp: new Date().toISOString()
                }, null, 2));
                this.cleanupWebSocket();
            }
        } catch (error) {
            console.error(`Error cleaning up existing WebSocket connection`, error);
            throw error;
        }

        const connId = ++this.connectionCounter;
        const wsUrl = await this.getWsUrl();
        const ctxt = `setupWebSocketConnection to ${wsUrl} #${connId}:`;

        const connectionPromise = new Promise<void>((resolve, reject) => {
            try {
                console.log(`${ctxt} creating`);
                this.wsConnection = new WebSocket(wsUrl);
                this.wsConnection.binaryType = 'arraybuffer';

                console.log(`${ctxt} adding event handlers`);
                this.wsConnection.onclose = (event) => {
                    console.log(`${ctxt} onclose`, JSON.stringify({
                        code: event.code,
                        reason: event.reason,
                        wasClean: event.wasClean,
                        timestamp: new Date().toISOString()
                    }, null, 2));
                    this.handleEarlyDisconnect(event);
                    this.cleanupWebSocket();
                };

                this.wsConnection.onerror = (event) => {
                    const errorMsg = `${ctxt} onerror`;
                    console.error(errorMsg, event, JSON.stringify({
                        url: this.wsConnection?.url,
                        readyState: this.wsConnection ? this.getReadyStateString(this.wsConnection.readyState) : 'null',
                        timestamp: new Date().toISOString()
                    }, null, 2));
                    this.cleanupWebSocket();
                    reject(new Error(errorMsg));
                };

                this.wsConnection.onopen = () => {
                    const ctxtOpen = `${ctxt} onopen`;
                    console.log(ctxtOpen, JSON.stringify({
                        url: this.wsConnection?.url,
                        timestamp: new Date().toISOString()
                    }, null, 2));
                    try {
                        this.sendConnectRequest();
                        if (!this.waitsForConnAckMsg) {
                            this.connAckd = true;
                            if (!isResolved) {
                                console.log(`${ctxt} onopen resolving`);
                                resolve();
                                isResolved = true; // Mark the promise as resolved
                            }
                        }
                    } catch (error) {
                        console.error(ctxtOpen, 'Error in sendConnectRequest:', error);
                        reject(error);
                    }
                };

                this.wsConnection.onmessage = (event) => {
                    try {
                        this.handleMessage(event);
                        if (this.connAckd && !isResolved) {
                            console.log(`${ctxt} onmessage resolving`);
                            resolve();
                            isResolved = true; // Mark the promise as resolved
                        }
                    } catch (error) {
                        console.error(`${ctxt} error in handleMessage:`, error);
                        this.handleMessageError?.(error);
                    }
                };

            } catch (error) {
                console.error(`Error setting up WebSocket connection #${this.connectionCounter}:`, error);
                this.cleanupWebSocket();
                reject(error);
            }
        });

        const timeoutPromise = new Promise<void>((_, reject) => {
            setTimeout(() => {
                if (isResolved) {
                    console.log(`${ctxt} connection timeout IGNORED after ${this.CONNECTION_TIMEOUT}ms`);
                } else {
                    console.error(`${ctxt} connection timeout after ${this.CONNECTION_TIMEOUT}ms`, JSON.stringify({
                        readyState: this.wsConnection ? this.getReadyStateString(this.wsConnection.readyState) : 'null',
                        timestamp: new Date().toISOString()
                    }, null, 2));
                    this.cleanupWebSocket();
                    reject(new Error(`${ctxt} connection timeout after ${this.CONNECTION_TIMEOUT}ms`));
                }
            }, this.CONNECTION_TIMEOUT);
        });

        try {
            return await Promise.race([connectionPromise, timeoutPromise]);
        } catch (error) {
            console.error(`${ctxt} connection failed:`, error);
            throw error;
        }
    }

    isConnected(): boolean {
        return this.wsConnection !== null &&
            this.wsConnection.readyState === WebSocket.OPEN;
    }

    protected handleEarlyDisconnect(_event: CloseEvent): void {
        // Base implementation does nothing
    }

    protected startKeepAlive(): void {
        if (this.keepAliveInterval) return;

        this.keepAliveInterval = window.setInterval(() => {
            if (this.wsConnection?.readyState === WebSocket.OPEN) {
                this.sendKeepAlive();
            }
        }, this.KEEPALIVE_INTERVAL);
    }

    protected stopKeepAlive(): void {
        if (this.keepAliveInterval) {
            window.clearInterval(this.keepAliveInterval);
            this.keepAliveInterval = null;
        }
    }

    protected abstract sendKeepAlive(): void;
} 
