<template>
  <div class="meeting-container">
    <div class="header">
      <h2>Group Meeting</h2>
    </div>

    <div class="controls">
      <button 
        @click="toggleMic"
        :class="{ active: !isMicMuted }"
      >
        {{ isMicMuted ? 'Unmute' : 'Mute' }} Microphone
      </button>
      <button 
        @click="openListenModal"
        class="listen-button"
        :class="{ 'pending': pendingRequestId !== null }"
        :disabled="pendingRequestId !== null"
      >
        {{ pendingRequestId ? 'Request Pending...' : 'Test Audio Listen' }}
      </button>
      <button 
        @click="handleLeave"
        :class="['leave-button', { 'terminate-button': canTerminate }]"
      >
        {{ canTerminate ? 'End Meeting for All' : 'Leave Meeting' }}
      </button>
    </div>

    <div class="audio-status" :class="{ recording: isRecording }">
      {{ isRecording ? 'Recording' : 'Not Recording' }}
    </div>

    <div v-if="lastListenResponse" class="listen-response" 
      :class="{ 
        'error': lastListenResponse.error, 
        'completed': !lastListenResponse.error && !pendingRequestId && !lastListenResponse.status.includes('Error'),
        'pending': pendingRequestId,
        'client-error': !lastListenResponse.error && lastListenResponse.status.includes('Error')
      }">
      <h4>Last Audio Listen Response:</h4>
      <p class="status-message"><em>Status: {{ lastListenResponse.status }}</em></p>
      <p><strong>Request ID:</strong> {{ lastListenResponse.requestId }}</p>
      <p class="success-value"><strong>Success:</strong> <span class="bold">{{ lastListenResponse.success }}</span></p>
      <p v-if="lastListenResponse.error" class="error-message"><strong>Server Error:</strong> {{ lastListenResponse.error }}</p>
    </div>

    <div class="participants">
      <h3>Participants</h3>
      <div v-for="participant in sortedParticipants" :key="participant.id" class="participant">
        {{ participant.name }}
        <span v-if="participant.id === currentUserUuid" class="current-user-badge">
          You
        </span>
        <span v-if="ownerUuids.has(participant.id)" class="owner-badge">
          Owner
        </span>
      </div>
      <div v-if="participants.length === 0" class="no-participants">
        No participants
      </div>
    </div>

    <!-- Audio Listen Modal -->
    <div v-if="showListenModal" class="modal-overlay">
      <div class="modal-content">
        <h3>Test Audio Listen Request</h3>
        <div class="form-group">
          <label for="prompt">Prompt:</label>
          <textarea 
            id="prompt" 
            v-model="listenRequest.prompt" 
            placeholder="Enter your prompt for the audio listen request"
            rows="4"
          ></textarea>
        </div>
        <div class="form-group">
          <label for="seconds">Duration (seconds):</label>
          <input 
            id="seconds" 
            type="number" 
            v-model.number="listenRequest.seconds" 
            min="1" 
            max="60"
          />
        </div>
        <div class="modal-actions">
          <button @click="sendListenRequest" class="send-button">Send Request</button>
          <button @click="closeListenModal" class="cancel-button">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { RealtimeAudioStreamingService, ParticipantUpdate } from '../RealtimeApiAudioManager';
import { ToGameServer, ToGameClient, AudioListenRequest, AudioListenResponse } from '../../proto-gen-ref/game_pb';
import { GameStreamingService } from '../GameManager';
import { v4 as uuidv4 } from 'uuid';

const router = useRouter();
const audioService = RealtimeAudioStreamingService.getInstance();
const gameService = GameStreamingService.getInstance();
const participants = ref<string[]>([]);
const ownerUuids = ref<Set<string>>(new Set());
const currentUserUuid = ref('');

// Audio Listen Request Modal
const showListenModal = ref(false);
const listenRequest = ref({
  prompt: localStorage.getItem('audioListenPrompt') || "is 'red' present in the input?",
  seconds: parseInt(localStorage.getItem('audioListenDuration') || '5')
});
const lastListenResponse = ref<{
  requestId: string;
  success: boolean;
  error?: string;
  status?: string; // New field for status messages like "Waiting for response..."
} | null>(null);
const pendingRequestId = ref<string | null>(null);

const isRecording = computed(() => audioService.isRecording());
const isMicMuted = computed(() => audioService.isMicMuted());

interface ParsedParticipant {
  id: string;
  name: string;
}

const parsedParticipants = computed<ParsedParticipant[]>(() => {
  return participants.value.map(p => {
    const [id, name] = p.split(':', 2);
    return { id, name };
  });
});

const sortedParticipants = computed(() => {
  return [...parsedParticipants.value].sort((a, b) => 
    a.name.localeCompare(b.name)
  );
});

const canTerminate = computed(() => ownerUuids.value.has(currentUserUuid.value));

const toggleMic = () => {
  audioService.toggleAudioMuting();
};

const handleLeave = async () => {
  if (canTerminate.value) {
    if (confirm('Are you sure you want to end the meeting for all participants?')) {
      await audioService.disconnect(); // This will trigger termination due to our changes in the service
      router.push('/');
    }
  } else {
    await audioService.disconnect();
    router.push('/');
  }
};

// Audio Listen Request functions
const openListenModal = () => {
  // Only allow opening the modal if no request is pending
  if (pendingRequestId.value) {
    console.log('Request already pending, cannot open modal');
    return;
  }
  showListenModal.value = true;
};

const closeListenModal = () => {
  showListenModal.value = false;
};

const sendListenRequest = async () => {
  try {
    // Save values to localStorage for persistence
    localStorage.setItem('audioListenPrompt', listenRequest.value.prompt);
    localStorage.setItem('audioListenDuration', listenRequest.value.seconds.toString());
    
    // Generate a unique request ID
    const requestId = uuidv4();
    
    // Store the current request ID for correlation with response
    pendingRequestId.value = requestId;
    
    // Create the request message
    const audioListenReq = new AudioListenRequest()
      .setRequestId(requestId)
      .setPrompt(listenRequest.value.prompt)
      .setSeconds(listenRequest.value.seconds);
    
    const toGameServer = new ToGameServer()
      .setAudioListenReq(audioListenReq);
    
    // Send the request
    console.log('Sending AudioListenRequest:', {
      requestId,
      prompt: listenRequest.value.prompt,
      seconds: listenRequest.value.seconds
    });
    
    await gameService.send(toGameServer);
    
    // Close the modal
    closeListenModal();
    
    // Show a temporary status
    lastListenResponse.value = {
      requestId,
      success: true,
      error: '',  // No error
      status: 'Waiting for response...'  // Status message
    };
  } catch (error) {
    console.error('Error sending AudioListenRequest:', error);
    lastListenResponse.value = {
      requestId: 'Error',
      success: false,
      error: '', // No server error
      status: `Error sending request to server: ${error instanceof Error ? error.message : String(error)}`
    };
    // Clear the pending request ID on error
    pendingRequestId.value = null;
  }
};

// Function to handle AudioListenResponse
const handleAudioListenResponse = (response: AudioListenResponse) => {
  console.log('Received AudioListenResponse:', {
    requestId: response.getRequestId(),
    success: response.getSuccess(),
    error: response.getError()
  });
  
  lastListenResponse.value = {
    requestId: response.getRequestId(),
    success: response.getSuccess(),
    error: response.getError()
  };
};

onMounted(() => {
  console.log('GroupMeeting component mounted, registering callbacks');
  
  const processParticipantUpdate = (update: ParticipantUpdate) => {
    console.log('Processing participant update:', {
      participants: update.participants,
      ownerUuids: Array.from(update.ownerUuids),
      currentUserUuid: update.currentUserUuid
    });
    participants.value = update.participants;
    ownerUuids.value = update.ownerUuids;
    currentUserUuid.value = update.currentUserUuid;
  };

  const bufferedUpdate = audioService.onParticipantUpdate(processParticipantUpdate);
  if (bufferedUpdate) {
    console.log('Processing buffered participant update');
    processParticipantUpdate(bufferedUpdate);
  }
  
  // Register for audio listen responses
  gameService.onStringMessage((typeCase, message) => {
    console.log('GameManager message received:', { typeCase, message });
    
    // Handle audio listen responses
    if (typeCase === ToGameClient.TypeCase.AUDIO_LISTEN_RESP) {
      try {
        const responseData = JSON.parse(message);
        
        // Check if this response matches our pending request
        if (pendingRequestId.value && responseData.requestId === pendingRequestId.value) {
          console.log('Matched response to pending request:', pendingRequestId.value);
          
          // Update the UI with the response
          lastListenResponse.value = {
            requestId: responseData.requestId,
            success: responseData.success,
            error: responseData.error, // Preserve the server's error message
            status: responseData.error ? 'Error received from server' : 'Response received successfully'
          };
          
          // Clear the pending request ID
          pendingRequestId.value = null;
        } else {
          // Handle responses that don't match our pending request
          console.warn('Received response for unknown or different request:', responseData.requestId);
          
          // Display an error in the UI
          lastListenResponse.value = {
            requestId: 'mismatch-' + responseData.requestId,
            success: false,
            error: '', // No server error
            status: `Received response for unknown or different request ID: ${responseData.requestId}`
          };
          
          // Clear any pending request ID to reset UI state
          pendingRequestId.value = null;
        }
      } catch (error) {
        console.error('Error parsing AUDIO_LISTEN_RESP message:', error);
        
        // Handle JSON parsing errors in the UI
        lastListenResponse.value = {
          requestId: 'parse-error',
          success: false,
          error: '', // No server error
          status: `Error parsing response: ${error instanceof Error ? error.message : String(error)}`
        };
        
        // Clear any pending request ID
        pendingRequestId.value = null;
      }
    }
  });
});

onUnmounted(() => {
  audioService.removeParticipantUpdateListeners();
});
</script>

<style scoped>
.meeting-container {
  padding: 20px;
}

.header {
  margin-bottom: 20px;
}

.controls {
  margin-bottom: 20px;
}

.participants {
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 15px;
}

.participant {
  padding: 8px;
  border-bottom: 1px solid #eee;
}

.participant:last-child {
  border-bottom: none;
}

.no-participants {
  color: #666;
  font-style: italic;
}

button {
  padding: 10px 20px;
  border-radius: 4px;
  border: 1px solid #ccc;
  cursor: pointer;
}

button.active {
  background-color: #4CAF50;
  color: white;
}

.listen-button {
  margin-left: 10px;
  background-color: #17a2b8;
  color: white;
  border: none;
}

.listen-button:hover {
  background-color: #138496;
}

.listen-button.pending {
  background-color: #6c757d;
  cursor: not-allowed;
}

.listen-button:disabled {
  opacity: 0.6;
}

.leave-button {
  margin-left: 10px;
  background-color: #ffc107;
  color: black;
  border: none;
}

.leave-button:hover {
  background-color: #e0a800;
}

.leave-button.terminate-button {
  background-color: #dc3545;
  color: white;
}

.leave-button.terminate-button:hover {
  background-color: #c82333;
}

.audio-status {
  margin: 10px 0;
  padding: 5px 10px;
  display: inline-block;
  border-radius: 4px;
  background-color: #dc3545;
  color: white;
}

.audio-status.recording {
  background-color: #28a745;
}

.owner-badge {
  margin-left: 8px;
  padding: 2px 6px;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  font-size: 0.8em;
}

.current-user-badge {
  margin-left: 8px;
  padding: 2px 6px;
  background-color: #6c757d;
  color: white;
  border-radius: 4px;
  font-size: 0.8em;
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group textarea, 
.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.modal-actions button {
  margin-left: 10px;
}

.send-button {
  background-color: #28a745;
  color: white;
  border: none;
}

.send-button:hover {
  background-color: #218838;
}

.cancel-button {
  background-color: #6c757d;
  color: white;
  border: none;
}

.cancel-button:hover {
  background-color: #5a6268;
}

.listen-response {
  margin: 15px 0;
  padding: 15px;
  border-radius: 4px;
  background-color: #f8f9fa; /* Light gray to contrast with white background */
  border: 1px solid #dee2e6;
  color: #212529;
}

/* When there's an error */
.listen-response.error {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  color: #721c24;
}

/* When request completed successfully (no error) */
.listen-response.completed {
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  color: #155724;
}

.listen-response .success-value .bold {
  font-weight: 700;
  font-size: 1.1em;
}

.listen-response .error-message {
  color: #dc3545;
}

.listen-response .status-message {
  color: #495057;
  font-style: italic;
  font-weight: 500;
  font-size: 1.05em;
  margin-bottom: 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid #dee2e6;
}

/* When a request is pending */
.listen-response.pending {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  color: #856404;
}

/* When there's a client-side error */
.listen-response.client-error {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  color: #721c24;
}
</style> 
