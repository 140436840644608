<template>
  <div class="join-meeting-overlay" @keydown.enter="selectMeeting(null, '/chat')">
    <div class="join-meeting-modal">
      <h3>Join Meeting</h3>
      <div class="meeting-list">
        <div 
          class="meeting-option default-option" 
          ref="defaultOption"
          tabindex="0"
          @click="selectMeeting('/chat', '/chat')">
          Start New AI Meeting
        </div>
        <div 
          class="meeting-option" 
          tabindex="0"
          @click="selectMeeting('/meeting', '/meeting')">
          Start New Group Meeting
        </div>
        <div 
          v-for="meeting in sortedMeetings" 
          :key="meeting.meetingId"
          class="meeting-option"
          tabindex="0"
          @click="selectMeeting(meeting.meetingId, meeting.route)">
          Join {{ meeting.description }}'s Meeting
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import type { defineProps, defineEmits } from 'vue';
import type { MeetingRoute } from '../types';

interface Meeting {
  description: string;
  meetingId: string;
  route: MeetingRoute;
}

const props = defineProps<{
  meetings: Meeting[];
}>();

const emit = defineEmits<{
  (e: 'meeting-selected', meetingId: string, route: MeetingRoute): void;
}>();

const defaultOption = ref<HTMLElement | null>(null);

const sortedMeetings = computed(() => {
  return [...props.meetings].sort((a, b) => a.description.localeCompare(b.description));
});

const selectMeeting = (meetingId: string, route: MeetingRoute) => {
  console.log('JoinExistingMeeting: selecting meeting with id:', meetingId, 'and route:', route);
  emit('meeting-selected', meetingId, route);
};

onMounted(() => {
  // Focus the "Start New Meeting" option by default
  defaultOption.value?.focus();
});
</script>

<style scoped>
.join-meeting-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.join-meeting-modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  min-width: 300px;
  max-width: 500px;
}

.meeting-list {
  max-height: 300px;
  overflow-y: auto;
  margin: 10px 0;
}

.meeting-option {
  padding: 10px;
  margin: 5px 0;
  cursor: pointer;
  border: 1px solid #eee;
  border-radius: 4px;
  outline: none;
}

.meeting-option:hover {
  background-color: #f5f5f5;
}

.meeting-option:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0,123,255,.25);
}

.default-option {
  font-weight: bold;
}
</style> 