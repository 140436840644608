// %~dp0/../../../openai-realtime-console/src/pages/ConsolePage.tsx
// %~dp0/../../../openai-realtime-console/relay-server/lib/relay.js

// RealtimeApiAudioManager.ts

import { ref, type Ref } from 'vue';
import { ToastPluginApi } from 'vue-toast-notification';
import {
  ToAudioServer,
  ToAudioClient,
  ClientConnectRequest,
  AudioFileUpload,
  UpstreamState
} from '../proto-gen-ref/audio_pb';
import { WavRecorder, WavStreamPlayer } from './lib/wavtools';
import { WebSocketManager } from './WebSocketManager';
import type { MeetingRoute } from './types';

interface AudioData {
  mono: ArrayBuffer;
  raw: ArrayBuffer;
}

interface AudioPacket {
  serializedMsg: Uint8Array;
  seqNum: number;
  durationMs: number;
}



interface ParticipantUpdate {
  participants: string[];
  ownerUuids: Set<string>;
  currentUserUuid: string;
}

export class RealtimeAudioStreamingService extends WebSocketManager {
  private static readonly SAMPLE_RATE = 24000;
  private static readonly SAMPLES_PER_RECORDER_CHUNK = 4096;  // Each sample is 2 bytes (16-bit PCM)

  private isWavPlayerPlaying: Ref<boolean>;
  private isMutingRecording: Ref<boolean>;
  private wavRecorder: Ref<WavRecorder>;
  private wavStreamPlayer: Ref<WavStreamPlayer>;
  private lastKnownUpstreamStatusPrimary: Ref<UpstreamState>;
  private lastKnownUpstreamStatusObserver: Ref<UpstreamState>;
  private chunkProcessorCallCount = 0;
  private speechMessageCallbacks: Array<(source: string, text: string, itemId: string) => void> = [];

  private audioConnKey: string | null = null;
  private isActivelyRecording: Ref<boolean> = ref(false);
  private selectedUserAudioMeetingId: string;
  private route: MeetingRoute;

  private lastParticipantUpdate: ParticipantUpdate | null = null;
  private participantUpdateCallbacks: ((update: ParticipantUpdate) => void)[] = [];

  constructor(
    wsPathname: string,
    toast: ToastPluginApi,
    userToken: string,
    selectedUserAudioMeetingId: string,
    route: MeetingRoute
  ) {
    super(wsPathname, false, toast, userToken);
    this.selectedUserAudioMeetingId = selectedUserAudioMeetingId;
    this.route = route;
    this.isWavPlayerPlaying = ref(false);
    this.isMutingRecording = ref(false);
    this.wavRecorder = ref(new WavRecorder({
      sampleRate: RealtimeAudioStreamingService.SAMPLE_RATE,
      debug: true
    }));
    this.wavStreamPlayer = ref(new WavStreamPlayer({
      sampleRate: RealtimeAudioStreamingService.SAMPLE_RATE
    }));
    this.lastKnownUpstreamStatusPrimary = ref(UpstreamState.NO_CONNECTION);
    this.lastKnownUpstreamStatusObserver = ref(UpstreamState.NO_CONNECTION);

    this.wavStreamPlayer.value.onTrackChange(({ previousTrackId, newTrackId }) => {
      console.log('wavStreamPlayer.onTrackChange', previousTrackId, '->', newTrackId);
      this.isWavPlayerPlaying.value = Boolean(newTrackId);
    });
  }

  private static instance: RealtimeAudioStreamingService | null = null;
  public static getInstance(): RealtimeAudioStreamingService {
    if (!this.instance) {
      throw new Error('RealtimeAudioStreamingService not initialized');
    }
    return this.instance;
  }

  public static async initialize(
    wsPathname: string,
    toast: ToastPluginApi,
    userToken: string,
    selectedUserAudioMeetingId: string,
    route: MeetingRoute
  ): Promise<RealtimeAudioStreamingService> {
    console.log('RealtimeAudioStreamingService: Starting initialize');
    if (this.instance) {
      console.log('RealtimeAudioStreamingService: Destroying previous instance');
      await RealtimeAudioStreamingService.destroyInstance();
    }
    console.log('RealtimeAudioStreamingService: Creating new instance');
    this.instance = new RealtimeAudioStreamingService(wsPathname, toast, userToken, selectedUserAudioMeetingId, route);
    console.log('RealtimeAudioStreamingService: Instance created');
    return this.instance;
  }

  public isMicMuted(): boolean {
    return this.isMutingRecording.value;
  }

  public isRecording(): boolean {
    return this.wavRecorder.value.getStatus() === 'recording';
  }

  public isPlayingBack(): boolean {
    return this.isWavPlayerPlaying.value;
  }

  public getLastKnownUpstreamStatusPrimary(): UpstreamState {
    return this.lastKnownUpstreamStatusPrimary.value;
  }

  public getLastKnownUpstreamStatusObserver(): UpstreamState {
    return this.lastKnownUpstreamStatusObserver.value;
  }

  private txAudio(serializedBinary: Uint8Array): void {
    // Only send audio if we're actively recording and connected
    if (!this.isActivelyRecording.value || !this.wsConnection || this.wsConnection.readyState !== WebSocket.OPEN) {
      console.log('Skipping txAudio - connection not ready or recording inactive');
      return;
    }
    this.wsConnection.send(serializedBinary);
  }

  private createAudioPacket(monoInt16Array: Int16Array, seqNum: number): AudioPacket {
    const abuf = new Uint8Array(
      monoInt16Array.buffer,
      monoInt16Array.byteOffset,
      monoInt16Array.byteLength
    );
    // console.log('createAudioPacket', 'monoInt16Array.length', monoInt16Array.length);
    const durationMs = Math.floor((1000 * monoInt16Array.length) / RealtimeAudioStreamingService.SAMPLE_RATE);
    const afu = new AudioFileUpload()
      .setAudio(abuf)
      .setAudioType('pcm16')
      .setAudioDurationMs(durationMs)
      .setSequenceNumber(seqNum);
    const toServer = new ToAudioServer()
      .setAudioRequest(afu);
    return { serializedMsg: toServer.serializeBinary(), seqNum, durationMs };
  }

  private handleAudioResponse(msg: ToAudioClient): void {
    const afu = msg.getAudioResponse();
    if (afu) {
      const trackId = afu.getItemId() || 'unknown';
      const receivedAudio = afu.getAudio_asU8();
      const audioBuffer = receivedAudio.buffer.slice(
        receivedAudio.byteOffset,
        receivedAudio.byteOffset + receivedAudio.byteLength
      );
      const audioInt16Array = new Int16Array(audioBuffer);
      this.wavStreamPlayer.value.add16BitPCM(audioInt16Array, trackId);
    }
  }

  protected async onCleanup(): Promise<void> {
    console.log('Performing audio cleanup - recorder status:', this.wavRecorder.value.getStatus());

    if (this.wavRecorder.value) {
      try {
        console.log('Calling quit() on recorder...');
        await this.wavRecorder.value.quit();
        console.log('quit() completed');
      } catch (error) {
        console.error('Error in recorder quit():', error);
      }
    }

    // Ensure we reset all state
    this.isActivelyRecording.value = false;
    this.chunkProcessorCallCount = 0;
    if (this.wavStreamPlayer.value) {
      this.wavStreamPlayer.value.interrupt().catch(console.error);
    }
    this.audioConnKey = null;
    this.lastKnownUpstreamStatusPrimary.value = UpstreamState.NO_CONNECTION;
    this.lastKnownUpstreamStatusObserver.value = UpstreamState.NO_CONNECTION;
  }

  protected override handleMessage(event: MessageEvent): void {
    const msg = ToAudioClient.deserializeBinary(new Uint8Array(event.data));
    console.log('Audio message received, type:', msg.getTypeCase());

    switch (msg.getTypeCase()) {
      case ToAudioClient.TypeCase.AUDIO_RESPONSE:
        // console.log('rxd ToAudioClient.AUDIO_RESPONSE');
        this.handleAudioResponse(msg);
        break;

      case ToAudioClient.TypeCase.AUDIO_TRANSCRIPT: {
        if (this.route === '/chat') {
          const transcript = msg.getAudioTranscript();
          if (transcript) {
            this.speechMessageCallbacks.forEach(callback => {
              callback(transcript.getSource(), transcript.getMessage(), transcript.getItemId());
            });
          }
        }
      } break;

      case ToAudioClient.TypeCase.UPSTREAM_STATUSES: {
        if (this.route === '/chat') {
          const newUpstreamStatuses = msg.getUpstreamStatuses();
          if (newUpstreamStatuses) {
            const primaryStatus = newUpstreamStatuses.getPrimary();
            const advisoryStatus = newUpstreamStatuses.getObserver();
            console.log('UPSTREAM_STATUSES',
              `Primary: ${this.lastKnownUpstreamStatusPrimary.value} -> ${primaryStatus}`,
              `Advisor: ${this.lastKnownUpstreamStatusObserver.value} -> ${advisoryStatus}`
            );
            this.lastKnownUpstreamStatusPrimary.value = primaryStatus;
            this.lastKnownUpstreamStatusObserver.value = advisoryStatus;
          }
        }
      } break;

      case ToAudioClient.TypeCase.PARTICIPANT_LIST: {
        console.log('Processing PARTICIPANT_LIST message');
        const participantList = msg.getParticipantList();
        if (participantList) {
          const participants = participantList.getParticipantsList();
          const ownerUuids = participantList.getOwnerUuidsList();
          const myUuid = participantList.getMyUuid();
          console.log('PARTICIPANT_LIST contents:', {
            participants,
            owners: ownerUuids,
            myUuid
          });
          const update: ParticipantUpdate = {
            participants,
            ownerUuids: new Set(ownerUuids),
            currentUserUuid: myUuid
          };
          this.lastParticipantUpdate = update;
          console.log('Dispatching to', this.participantUpdateCallbacks.length, 'callbacks');
          this.participantUpdateCallbacks.forEach(callback => callback(update));
        } else {
          console.log('Received empty participant list');
        }
      } break;

      case ToAudioClient.TypeCase.MEETING_TERMINATED: {
        const message = msg.getMeetingTerminated();
        
        console.log('Meeting terminated - resetting page in 4 seconds');
        
        // Show toast message
        this.toast.info(`Meeting terminated: ${message}. Page will refresh in a moment.`, {
          position: 'top-right',
          dismissible: true,
          duration: 4000,
        });
        
        // Use a timeout to allow the termination message to be shown to the user
        setTimeout(() => {
          // Force page reload
          window.location.reload();
        }, 4000);
      } break;

      case ToAudioClient.TypeCase.INPUT_AUDIO_START_STOP: {
        const startStop = msg.getInputAudioStartStop();
        if (startStop) {
          console.log('Received INPUT_AUDIO_START_STOP:', startStop ? 'START' : 'STOP');
        }
      } break;
    }
  }

  protected sendConnectRequest(): void {
    if (!this.audioConnKey || !this.wsConnection) {
      throw new Error('Cannot send connect request: missing audioConnKey or wsConnection');
    }
    const meetingId = this.selectedUserAudioMeetingId || this.route;
    console.log('sendConnectRequest: meetingId', meetingId);
    const connReq = new ClientConnectRequest()
      .setAuthKey(this.audioConnKey)
      .setUserIdent(this.userToken)
      .setUserAudioMeetingId(meetingId);
    const tac = new ToAudioServer()
      .setConnreq(connReq);
    this.wsConnection.send(tac.serializeBinary());
  }

  public async connectWithKey(audioConnKey: string): Promise<void> {
    // Clean up any existing connection first
    await this.disconnect();

    // Connect to Audio WebSocket server
    this.audioConnKey = audioConnKey;
    await this.connectWebSocketWaitForAck();

    if (!this.wsConnection || this.wsConnection.readyState !== WebSocket.OPEN) {
      throw new Error('RealtimeAudioStreamingService: audio ws connection failed');
    }
    console.log('RealtimeAudioStreamingService: connected to audio ws server');

    // Set up audio components
    try {
      if (!this.wavStreamPlayer.value) {
        throw new Error('WavStreamPlayer not initialized');
      }

      await this.wavStreamPlayer.value.connect();
      console.log('RealtimeAudioStreamingService: wavStreamPlayer.connect');

      // Start recording
      await this.wavRecorder.value.begin();
      console.log('RealtimeAudioStreamingService: wavRecorder.begin');
      this.isActivelyRecording.value = true;
      this.chunkProcessorCallCount = 0;

      // Set up recording callback
      await this.wavRecorder.value.record((data: AudioData) => {
        if (!this.isMutingRecording.value) {
          const monoInt16Array = new Int16Array(data.mono);
          if (monoInt16Array.byteLength > 0) {
            const audioPacket = this.createAudioPacket(monoInt16Array, this.chunkProcessorCallCount++);
            this.txAudio(audioPacket.serializedMsg);
          }
        }
        return true;
      }, RealtimeAudioStreamingService.SAMPLES_PER_RECORDER_CHUNK * 2);
      console.log('RealtimeAudioStreamingService: connectWithKey successful');
    } catch (error) {
      console.error('RealtimeAudioStreamingService: connectWithKey failed:', error);
      await this.disconnect();
      throw error;
    }
  }

  public toggleAudioMuting(): void {
    this.isMutingRecording.value = !this.isMutingRecording.value;

    // Start/stop keep-alive based on mute state
    if (this.isMutingRecording.value) {
      this.startKeepAlive();
    } else {
      this.stopKeepAlive();
    }
  }

  public onSpeechMessage(callback: (source: string, text: string, itemId: string) => void) {
    this.speechMessageCallbacks.push(callback);
  }

  public submitText(text: string): void {
    if (this.route !== '/chat') return;
    const toServer = new ToAudioServer()
      .setUserSubmittedText(text);
    this.txAudio(toServer.serializeBinary());
  }

  public toggleMonologMode(): void {
    if (this.route !== '/chat') return;
    const newMsg = new ToAudioServer();
    newMsg.setToggleMonologMode(false); // value is irrelevant
    this.txAudio(newMsg.serializeBinary());
  }

  public toggleObserverMode(): void {
    if (this.route !== '/chat') return;
    const newMsg = new ToAudioServer();
    newMsg.setToggleAiObserverMode(false); // value is irrelevant
    this.txAudio(newMsg.serializeBinary());
  }

  // Add a destructor method
  public async destroy(): Promise<void> {
    await this.disconnect();
    // Don't null the instance here - that's handled by destroyInstance
  }

  public static async destroyInstance(): Promise<void> {
    if (this.instance) {
      try {
        await this.instance.destroy();
      } finally {
        // Ensure instance is nulled even if destroy throws
        this.instance = null;
      }
    }
  }

  public onParticipantUpdate(callback: (update: ParticipantUpdate) => void): ParticipantUpdate | null {
    this.participantUpdateCallbacks.push(callback);
    return this.lastParticipantUpdate;  // Return any buffered update
  }

  public removeParticipantUpdateListeners(): void {
    this.participantUpdateCallbacks = [];
  }

  public terminateMeeting(): void {
    const toServer = new ToAudioServer();
    toServer.setTerminateMeeting('User initiated');
    this.txAudio(toServer.serializeBinary());
  }


  protected sendKeepAlive(): void {
    const pingMsg = new ToAudioServer();
    pingMsg.setPing(Date.now());
    this.txAudio(pingMsg.serializeBinary());
  }
}
